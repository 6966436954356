const hasInvalidItemQtd = (verifiedQtd, qtd, divergence) => {
  if (verifiedQtd > qtd) return true
  if (verifiedQtd < qtd && divergence === '') return true

  return false
}

const hasInvalidItemBatch = (batch, manufacturingDate, expirationDate, batchControl) => {
  if (batchControl === 'true') {
    if (isBatchPresent(batch) && !!manufacturingDate && !!expirationDate) return false
  } else {
    return false
  }
  return true
}

const isBatchPresent = batch => {
  return batch.trim().length > 0
}

export { hasInvalidItemQtd, hasInvalidItemBatch }
