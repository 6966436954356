import { Controller } from 'stimulus'
import $ from 'jquery'
import dropdown from 'semantic-ui-dropdown'
import transition from 'semantic-ui-transition'

$.fn.dropdown = dropdown
$.fn.transition = transition

export default class extends Controller {
  static targets = ['value', 'installments', 'paymentMethod', 'cardNsu', 'cardBrand']

  connect() {
    this.handleChange()
    this.toggleInstallments()
    this.toggleCreditCardExtras()
  }

  handleChange() {
    const calcValue = this.calcPayment()

    this.data.set('total-value', calcValue)
    this.notifyChange()
  }

  toggleCreditCardExtras() {
    if (this.data.get('handle-credit-extras') != 'true') return

    const paymentWithCreditExtra = ['credit_card', 'debit_card']
    const paymentWithTicketsExtra = ['va_ticket', 'vr_ticket', 'gift_ticket', 'fuel_ticket']

    if (paymentWithCreditExtra.indexOf(this.paymentMethodTarget.value) >= 0 ) {
      this.cardNsuTarget.classList.remove('is-disabled')
      this.cardBrandTarget.classList.remove('is-disabled')
    } else if (paymentWithTicketsExtra.indexOf(this.paymentMethodTarget.value) >= 0 ) {
      this.cardNsuTarget.classList.remove('is-disabled')
      this.cardBrandTarget.classList.add('is-disabled')
    } else {
      this.cardNsuTarget.classList.add('is-disabled')
      this.cardBrandTarget.classList.add('is-disabled')
    }
  }

  toggleInstallments() {
    if (this.data.get('handle-installments') != 'true') return

    const paymentWithInstallments = ['credit_card', 'creditor', 'others']
    const $fieldContainer = $(this.installmentsTarget).parents('.field')

    if (paymentWithInstallments.indexOf(this.paymentMethodTarget.value) >= 0 ) {
      $fieldContainer.removeClass('is-disabled')
      $fieldContainer.children().removeClass('disabled')
    } else {
      $fieldContainer.find('.item[data-value="1"]').trigger('click')
      $fieldContainer.addClass('is-disabled')
      $fieldContainer.children().addClass('disabled')
    }
  }

  handlePaymentMethodChange() {
    this.toggleInstallments()
    this.toggleCreditCardExtras()
  }

  calcPayment() {
    return (Number(this.valueTarget.value || 0))
  }

  notifyChange() {
    const customEvent = new CustomEvent('paymentChanged')
    window.dispatchEvent(customEvent)
  }
}
