const getEANDivergences = (items, conferenceFile) => {
  const { uniqueEANs } = getItemsEAN(items, conferenceFile)

  return uniqueEANs
    .map(ean => createDivergences(ean, items, conferenceFile))
    .filter(divergence => divergence.quantity !== 0)
}

const createDivergences = (ean, items, conferenceFile) => {
  const { xmlEanItems, conferenceFileEanItems } = getItemsEAN(items, conferenceFile)
  const { xmlItem, conferenceFileItem } = foundedItems(ean, items, conferenceFile)
  const quantity = getDivergentQuantity(xmlItem, conferenceFileItem)

  return {
    ean,
    quantity,
    xmlPresence: xmlEanItems.includes(ean),
    txtPresence: conferenceFileEanItems.includes(ean),
  }
}

const foundedItems = (ean, items, conferenceFile) => {
  const xmlItem = items.find(item => item.ean === ean)
  const conferenceFileItem = conferenceFile.find(item => item.ean === ean)

  return { xmlItem, conferenceFileItem }
}

const getDivergentQuantity = (xmlItem, conferenceFileItem) => {
  const xmlEanQtd = Number(xmlItem?.qtd) || 0
  const conferenceFileQtd = Number(conferenceFileItem?.qtd) || 0

  return conferenceFileQtd - xmlEanQtd
}

const getItemsEAN = (items, conferenceFile) => {
  const xmlEanItems = items.map(item => item.ean)
  const conferenceFileEanItems = conferenceFile.map(item => item.ean)
  const uniqueEANs = Array.from(new Set([...xmlEanItems, ...conferenceFileEanItems]))

  return { xmlEanItems, conferenceFileEanItems, uniqueEANs }
}

export default getEANDivergences
