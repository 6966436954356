import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.setBackgroundColor()
  }

  setBackgroundColor() {
    const avatar = this.containerTarget
    const id = parseInt(avatar.dataset.avatarId) || 1
    const wordHue = this.genereateWordHue(avatar.title, id)

    avatar.style.backgroundColor = `hsla(${wordHue}, 75%, 50%, .9)`;
  }

  genereateWordHue(word, id) {
    const lettersCodePointAtSum = this.reduceLettersCodePointAt(word)

    return Math.floor((lettersCodePointAtSum + 90) * id) % 360
  }

  reduceLettersCodePointAt(word) {
    return word.split("").reduce((acc, curr) => (
      String(acc).codePointAt() + String(curr).codePointAt()
    ))
  }
}
