import { Controller } from 'stimulus'
import { getEANDivergences } from '../utils/stock_movements'

export default class extends Controller {
  static targets = ['tableContent', 'divergencesTotal']

  checkForDivergences({ detail }) {
    const { conferenceFile, items } = detail

    if(this.hasExpectedQuantity(conferenceFile, items)) return

    this.showDivergences(conferenceFile, items)
  }

  hasExpectedQuantity(conferenceFile, items) {
    const eanList = this.getEANList(conferenceFile, items)

    return eanList.every(ean => {
      const xmlItem = items.find(item => item.ean === ean)
      const conferenceFileItem = conferenceFile.find(item => item.ean === ean)

      const xmlEanQtd = Number(xmlItem?.qtd) || 0
      const conferenceFileQtd = Number(conferenceFileItem?.qtd) || 0

      return xmlEanQtd === conferenceFileQtd
    })
  }

  showDivergences(conferenceFile, items) {
    const messageBoxId = this.data.get('messageBoxId')
    const messageBox = document.getElementById(messageBoxId)

    const divergences = getEANDivergences(items, conferenceFile)

    this.tableContentTarget.innerHTML = ''

    messageBox.classList.remove('hidden')

    this.appendHTML(divergences)
    this.setDivergencesTotal(divergences.length)
  }

  setDivergencesTotal(total) {
    const placeholder = this.divergencesTotalTarget.textContent
    const newText = placeholder.replace(/#number_of_divergences/, total)

    this.divergencesTotalTarget.textContent = newText
  }

  appendHTML(divergences) {
    const rows = divergences.map(this.buildTableRowsHTML)

    rows.forEach(row => this.tableContentTarget.appendChild(row))
  }

  buildTableRowsHTML = divergence => {
    const row = document.createElement('tr')

    const xmlValue = divergence.xmlPresence ? divergence.ean : ''
    const txtValue = divergence.txtPresence ? divergence.ean : ''

    const quantTextClasses = divergence.quantity < 0 ? ['ch-text--center', 'ch-text--negative'] : ['ch-text--center']

    row.appendChild(this.buildTableRowColumnHTML(xmlValue))
    row.appendChild(this.buildTableRowColumnHTML(txtValue))
    row.appendChild(this.buildTableRowColumnHTML(divergence.quantity, quantTextClasses))

    return row
  }

  buildTableRowColumnHTML(content, classes) {
    const td = document.createElement('td')

    if (classes) td.classList.add(...classes)

    td.innerHTML = content

    return td
  }

  getEANList(conferenceFile, items) {
    const xmlEanItems = items.map(item => item.ean)
    const conferenceFileEanItems = conferenceFile.map(item => item.ean)

    return Array.from(new Set([...xmlEanItems, ...conferenceFileEanItems]))
  }
}
