import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['text', 'hiddenInput', 'actions']

  enable() {
    this.hiddenInputTarget.value = this.data.get('initialValue')
    this.hiddenInputTarget.hidden = false
    this.actionsTarget.hidden = false
    this.textTarget.hidden = true
    this.hiddenInputTarget.focus()
  }

  clear() {
    this.hiddenInputTarget.value = ''
    this.hiddenInputTarget.hidden = true
    this.actionsTarget.hidden = true
    this.textTarget.hidden = false
  }
}
