import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["type", "nameLabel", "document", "stateInscription", "municipalInscription", "birthday", "gender"]

  connect() {
    this.handleChange()
  }

  handleChange() {
    this.toggleInputsFor(this.typeTarget.value)
  }

  toggleInputsFor(type) {
    if (type == 'person') this.setInputsForPerson()
    if (type == 'company') this.setInputsForCompany()

    this.documentTarget.querySelector('input').dispatchEvent(new Event('input'))
  }

  setInputsForPerson() {
    this.nameLabelTarget.textContent = 'Nome'
    this.documentTarget.querySelector('label').textContent = 'CPF'
    this.documentTarget.dataset.maskType = 'cpf'

    this.disableInput(this.stateInscriptionTarget)
    this.disableInput(this.municipalInscriptionTarget)
    this.enableInput(this.birthdayTarget)
    this.enableInput(this.genderTarget)
  }


  setInputsForCompany() {
    this.nameLabelTarget.textContent = 'Razão Social'
    this.documentTarget.querySelector('label').textContent = 'CNPJ'
    this.documentTarget.dataset.maskType = 'cnpj'

    this.enableInput(this.stateInscriptionTarget)
    this.enableInput(this.municipalInscriptionTarget)
    this.disableInput(this.birthdayTarget)
    this.disableInput(this.genderTarget)
  }

  enableInput(container) {
    container.classList.remove('element-hidden')
    container.querySelector('input').disabled = false
  }

  disableInput(container) {
    container.classList.add('element-hidden')
    container.querySelector('input').disabled = true
  }
}
