import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "latitude",
    "longitude",
    "zipCode",
    "street",
    "number",
    "city",
    "complement",
    "state",
    "neighborhood"
  ]

  async connect() {
    const address = this.getAddress()

    if (!address) return

    this.plotMap()
  }

  getAddress() {
    const address = [
      this.streetTarget.value,
      this.numberTarget.value,
      this.cityTarget.value,
      this.stateTarget.value,
      this.zipCodeTarget.value,
      this.neighborhoodTarget.value,
      this.latitudeTarget.value,
      this.longitudeTarget.value,
      this.complementTarget.value
    ]

    return address.reduce((acc, curr) =>
      curr !== ''
        ? `${acc}, ${curr}`
        : acc, ''
    )
  }

  async plotMap () {
    const address = this.getAddress()
    const location = await this.getLocation()

    this.setLatLong(location)

    const config = {
      address,
      location,
      appendTo: '#maps-content',
      apiKey: mapsApiKey,
      label: document.querySelector('#maps-content').dataset.label,
    }

    this.map = new GMaps(config)
  }

  setLatLong (location) {
    const { lat, lng } = location

    this.latitudeTarget.value = lat
    this.longitudeTarget.value = lng
  }

  async getLocation () {
    const address = this.getAddress()
    const query = new URLSearchParams({ address }).toString()

    const response = await fetch(`/geolocations?${query}`)
    const data = await response.json()

    if (data.status === 'INVALID_REQUEST') {
      return { lat: 0, lng: 0 }
    }

    const { location } = data.results[0].geometry

    return location
  }
}
