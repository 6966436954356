import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'input'
  ]

  connect() {
    this.toggleDefaultButtons(this.inputTarget.checked)
  }

  handleChange({ target }) {
    const { checked } = target

    this.toggleDefaultButtons(checked)
  }


  toggleDefaultButtons(visiblity) {
    const validatyDaysField = document.querySelector('[data-attr="validity-days"]')
    const nutritionFactsButton = document.querySelector('[data-attr="nutrition-facts"]')
    const weighableFields = document.querySelector('[data-attr="weighable-fields"]')
    
    if (visiblity === true) {
      validatyDaysField.removeAttribute('hidden')
      nutritionFactsButton.removeAttribute('hidden')
      weighableFields.removeAttribute('hidden')
    } else {
      validatyDaysField.setAttribute('hidden', 'hidden')
      nutritionFactsButton.setAttribute('hidden', 'hidden')
      weighableFields.setAttribute('hidden', 'hidden')
    }
  }

}
