import tinymce from 'tinymce/tinymce'

import 'tinymce/icons/default'

import 'tinymce/models/dom'
import 'tinymce/themes/silver'

import 'tinymce/skins/ui/oxide/skin.css'

import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'

import 'tinymce/plugins/preview'
import 'tinymce/plugins/code'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/advlist'


import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  initialize () {
    this.defaults = {
      plugins:
        `preview code lists table visualblocks visualchars fullscreen image link media  charmap pagebreak nonbreaking advlist`,
      menubar: `file edit view insert format tools table`,
      toolbar:
        `fullscreen |
          undo redo |
          bold italic underline strikethrough |
          fontselect fontsizeselect formatselect |
          alignleft aligncenter alignright alignjustify |
          outdent indent |
          numlist bullist preview |
          forecolor backcolor removeformat |
          pagebreak |
          charmap |
          insertfile image media link |
          ltr rtl`,
      toolbar_sticky: true
    }
  }

  connect () {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect () {
    tinymce.remove()
  }
}
