import { Controller } from 'stimulus'
import numeral from 'numeral'
import 'numeral/locales'

const moneyFormat = '0,0.00'

export default class extends Controller {
  static targets = ['value', 'quantity', 'discount', 'subTotalValue', 'totalValue', 'totalDiscount', 'shipping']

  initialize() {
    numeral.locale('pt-BR')
  }

  connect() {
    this.handleChange()
  }

  handleChange() {
    const shipping = Number(this.shippingTargets[0].value)
    const subTotal = Math.max(0, this.sumQuantities() + shipping)
    const totalDiscount = Math.max(0, this.sumDiscounts())
    const totalValue = Math.max(0, subTotal - totalDiscount)

    this.setValueToTargets(this.subTotalValueTargets, numeral(subTotal).format(moneyFormat))
    this.setValueToTargets(this.totalDiscountTargets, numeral(totalDiscount).format(moneyFormat))
    this.setValueToTargets(this.totalValueTargets, numeral(totalValue).format(moneyFormat))
  }

  sumQuantities() {
    let totals = []

    this.valueTargets.forEach((target, index) => {
      const value = Number(target.value)
      const quantity = Number(this.quantityTargets[index].value)

      totals.push(value * quantity)
    })

    return totals.reduce((acc, curr) => curr + acc, 0)
  }

  sumDiscounts() {
    return this.discountTargets.map(target => Number(target.value)).reduce((acc, curr) => curr + acc, 0)
  }

  setValueToTargets(targets, value) {
    targets.forEach(target => {
      if (target.tagName == 'INPUT') {
        target.value = value
      } else target.innerHTML = value

      target.dispatchEvent(new Event('change'))
    })
  }
}
