import $ from 'jquery'
import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

import progress from 'semantic-ui-progress'

$.fn.progress = progress

export default class extends Controller {
  static targets = ['progressbar']

  connect() {
    this.channel = consumer.subscriptions.create({
      channel: this.data.get('channelName'),
      [this.data.get('channelParam')]: this.data.get('channelId')
    },
    {
      connected: () => {},
      disconnected: () => {},
      received: this.handleData.bind(this),
    });
  }

  showProgressBar() {
    this.element.removeAttribute('hidden')
  }

  updateProgressBar(total, processed) {
    $(this.progressbarTarget).progress({
      total: total,
      text: {
        active  : 'Processando {value} de {total} itens',
        success : '{total} itens processados!'
      }
    }).progress('set progress', processed)
  }

  reloadIfFinished(finished) {
    if (finished) window.location.replace(window.location.href)
  }

  handleData(data) {
    const { total, processed } = data.message

    this.showProgressBar()
    this.updateProgressBar(total, processed)
    this.reloadIfFinished(data.refresh)
  }
}
