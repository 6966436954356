import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'productChecked',
    'returnType',
    'quantity',
    'submitButton',
    'freight',
    'cancelationReason',
    'productInfo',
    'stocks',
    'customerAddress',
    'customerAddressCount',
    'customer',
    'customerWithDocument',
  ]

  connect() {
    const that = this

    if (this.element.dataset.deliveryProblem) {
      this.handleTotalDevolution()
    }

    this.toggleSubmitButton()
    const listener = function(e) {
      const documentPresent = e.detail.documentPresent
      const count = e.detail.addressCount

      that.customerAddressCountTarget.value = count
      that.customerWithDocumentTarget.value = documentPresent

      that.toggleSubmitButton()
    }

    document.removeEventListener('addressesLoaded', listener)
    document.addEventListener('addressesLoaded', listener)
  }

  handleChange(event) {
    if (event.target.dataset.target == 'products-return.returnType') this.handleTotalDevolution()

    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    this.submitButtonTarget.classList.toggle('is-disabled', !this.formValid())
  }

  formValid() {
    return (
      this.freightTarget.value != '' &&
      this.hasCancelationReason() &&
      this.hasProductChecked() &&
      this.customerInfoFilled()
    )
  }

  customerInfoFilled() {
    if (this.customerAddressCountTarget.value === '0') return false
    if (this.customerWithDocumentTarget.value === false || this.customerWithDocumentTarget.value === 'false')
      return false

    return true
  }

  handleTotalDevolution() {
    if (this.returnTypeTarget.value == 'total') {
      this.productInfoTargets.forEach(ele => ele.classList.add('is-disabled'))

      this.quantityTargets.forEach(input => {
        input.value = input.max
        input.classList.add('is-disabled')
      })

      this.productCheckedTargets.forEach((input, index) => {
        if (this.quantityTargets[index].value <= 0) {
          input.checked = false
        } else {
          input.checked = true
        }
        input.classList.add('is-disabled')
      })
    } else {
      this.productInfoTargets.forEach(ele => ele.classList.remove('is-disabled'))

      this.quantityTargets.forEach(input => {
        input.value = 0
        input.classList.remove('is-disabled')
      })

      this.productCheckedTargets.forEach(input => {
        input.checked = false
        input.classList.remove('is-disabled')
      })
    }
  }

  hasProductChecked() {
    if (this.returnTypeTarget.value == 'total') {
      let availableProductCheckedTarget = []
      let availableProductQuantityTarget = []
      for (let index = 0; index < this.productCheckedTargets.length; index++) {
        if (Number(this.quantityTargets[index].max) !== 0) {
          availableProductCheckedTarget.push(this.productCheckedTargets[index])
          availableProductQuantityTarget.push(this.quantityTargets[index])
        }
      }

      return (
        availableProductCheckedTarget.every(input => input.checked) &&
        availableProductQuantityTarget.every(input => input.value == input.max)
      )
    } else {
      return (
        this.productCheckedTargets.find(input => input.checked) && this.quantityTargets.find(input => input.value > 0)
      )
    }
  }

  hasCancelationReason() {
    const cancelReasonEmpty = this.cancelationReasonTarget.value == ''

    if (cancelReasonEmpty) return false

    return true
  }
}
