import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'dropArea', 'title', 'description', 'icon', 'preview', 'deleteImage']

  connect() {
    this.options = JSON.parse(this.data.get('options'))
  }

  allowDrop(event) {
    event.preventDefault()
  }

  handleDragEnter(event) {
    this.toggle('dragover', event)
  }

  handleDragLeave(event) {
    this.toggle('default', event)
  }

  toggle(state, event) {
    event.preventDefault()

    this.updateState(state)
  }

  updateState(state) {
    const action = state !== 'default' ? 'add' : 'remove'
    const { messages, icons } = this.options

    this.dropAreaTarget.classList[action]('is-active')
    if (this.hasIconTarget) this.iconTarget.classList = icons[state]
    this.titleTarget.textContent = messages[state].title
    this.descriptionTarget.textContent = messages[state].description || ''
  }

  handleDrop(event) {
    event.preventDefault()

    const { files } = event.dataTransfer

    this.inputTarget.files = files
    this.handleChange()
  }

  reset() {
    if (this.previewTarget.src) URL.revokeObjectURL(this.previewTarget.src)
    this.previewTarget.setAttribute('hidden', 'hidden')
    if (this.hasIconTarget) this.iconTarget.removeAttribute('hidden')
    this.clearInput()
    this.updateState('default')
  }

  clearInput() {
    this.inputTarget.value = null
    this.deleteImageTarget.value = true
  }

  handleChange() {
    if (this.inputTarget.files.length === 0) return

    this.upload()
  }

  upload() {
    const { messages } = this.options
    this.dropAreaTarget.classList.add('is-active')
    if (this.hasIconTarget) this.iconTarget.setAttribute('hidden', 'hidden')
    this.titleTarget.textContent = ''
    this.descriptionTarget.textContent = this.inputTarget.files[0].name
    this.previewTarget.src = URL.createObjectURL(this.inputTarget.files[0])
    this.deleteImageTarget.value = false

    this.previewTarget.removeAttribute('hidden')
  }
}
