import { Controller } from 'stimulus'
import consumer from '../channels/consumer'

import progress from 'semantic-ui-progress'

$.fn.progress = progress

import $ from 'jquery'
import dropdown from 'semantic-ui-dropdown'
import transition from 'semantic-ui-transition'

$.fn.dropdown = dropdown
$.fn.transition = transition

export default class extends Controller {
  static targets = ['template', 'submit', 'progressbar']

  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: this.data.get('channelName'),
        file_channel_id: this.data.get('channelId'),
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: this.handleData.bind(this),
      }
    )
  }

  redirectTo(stock_movement) {
    window.location.href=`stock_movements/${stock_movement.id}`
    this.afterRender()
  }

  renderErrors(errors) {
    if(!errors) return;
    console.warn(errors)
  }

  afterRender() {
    this.setCsrfToken()
    this.setDropdown()
    this.setDateMasks()
    this.setProductSkuSearch()
    this.enableSubmit()
  }

  enableSubmit() {
    $('[data-target="stock-movements-consumer.submit"]').removeClass('is-disabled')
  }

  setDateMasks() {
    this.templateTarget.querySelectorAll('input[data-date]').forEach(addDatePickerTo)
  }

  setProductSkuSearch() {
    this.templateTarget
      .querySelectorAll('form[data-stock-operation="entry"] [data-attr="product_sku_id"]')
      .forEach(initProductSkusSearch)
  }

  setDropdown() {
    $(this.templateTarget)
      .find('.ui.dropdown')
      .dropdown()
  }

  setCsrfToken() {
    const csrf_token = document.querySelector('meta[name="csrf-token"]')?.attributes.content.value

    this.templateTarget.querySelector("input[name='authenticity_token']").value = csrf_token
  }

  showProgressBar() {
    this.element.removeAttribute('hidden')
  }

  updateProgressBar(total, processed) {
    $(this.progressbarTarget).progress({
      total: total,
      text: {
        active  : 'Processando {value} de {total} itens',
        success : '{total} itens processados!'
      }
    }).progress('set progress', processed)
  }

  hiddenProgreeBar() {
    $('[data-attr="bar-progress"]').addClass('element-hidden')
  }

  handleData(data) {
    const { template, errors, total, processed, refresh, stock_movement } = data

   if (refresh === true) {
      this.hiddenProgreeBar()
      this.redirectTo(stock_movement)
      this.renderErrors(errors)
   } else {
     this.showProgressBar()
     this.updateProgressBar(total, processed)
   }
  }
}
