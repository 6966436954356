import { Controller } from 'stimulus'
import numeral from 'numeral'
import 'numeral/locales'

const moneyFormat = '0,0.00'

export default class extends Controller {
  static targets = ['value', 'productChecked', 'modalToConfirm', 'modal', 'unitTotalValue', 'discount',
    'quantity', 'totalQuantity', 'submitButton', 'productInfo', 'cancelItemsValue', 'orderTotalValue',
    'shippingValue', 'shipping', 'proportionalFreight', 'totalValue', 'totalOrderItems', 'modalText']

  connect() {
    this.calculateValues()
    const that = $(this.modalTarget)

    $(this.modalToConfirmTarget).on('click', ()=> {
      that.modal('show')
    });
  }

  handleChange() {
    this.calculateValues()
  }

  calculateValues() {
    this.sumTotalUnitValue()
  }

  sumTotalUnitValue() {
    let totals = []

    this.valueTargets.forEach((target, index) => {
      const value = Number(target.value)

      if(value < 0) {
        this.setValueToTargets(this.unitTotalValueTargets, numeral(0).format(moneyFormat), index)

        totals.push(value)
      } else {
        const quantity = Number(this.quantityTargets[index].value)
        const discount = Number(this.discountTargets[index].value)
        const totalItemQuantity = Number(this.totalQuantityTargets[index].value)
        const totalDiscount = (quantity * discount) / totalItemQuantity
        const totalUnitValue = ((value * quantity) - totalDiscount)

        this.setValueToTargets(this.unitTotalValueTargets, numeral(totalUnitValue).format(moneyFormat), index)

        totals.push(totalUnitValue)
      }

      const totalValueToCancel = totals.reduce((acc, curr) => (curr + acc), 0 )

      this.cancelItemsValueTarget.innerHTML = numeral(totalValueToCancel).format(moneyFormat)
      this.calculateFooterInfo(totalValueToCancel)
    })
  }

  calculateFooterInfo(totalValueToCancel) {
    let totalQuantitySelect = []
    this.quantityTargets.forEach((target) => {
      const qt = Number(target.value)
      totalQuantitySelect.push(qt)
    })

    const totalSelected = totalQuantitySelect.reduce((acc, curr) => (curr + acc), 0 )
    const shippingValue = this.shippingTarget.value
    const totalOrderItems = this.totalOrderItemsTarget.value
    if(shippingValue == 0) {
      const proportionalShipping = 0
      this.proportionalFreightTarget.innerHTML = numeral(proportionalShipping).format(moneyFormat)

      const formatValue = numeral(totalValueToCancel).format(moneyFormat)

      this.totalValueTarget.innerHTML = formatValue
      this.addTextToModal(formatValue)
    } else {
      const proportionalShipping = (shippingValue / totalOrderItems) * totalSelected
      this.proportionalFreightTarget.innerHTML = numeral(proportionalShipping).format(moneyFormat)

      const totalToCancel = totalValueToCancel + proportionalShipping
      const formatValue = numeral(totalToCancel).format(moneyFormat)
      this.totalValueTarget.innerHTML = formatValue
      this.addTextToModal(formatValue)
    }
  }

  addTextToModal(value) {
    const modalText = `Tem certeza que deseja <b> cancelar </b> os itens selecionados do pedido? <br> Um novo pedido será criado sem os itens e quantidades canceladas, o valor de <b> RS ${value} </b> será removido do total do pedido`
    this.modalTextTarget.innerHTML = modalText
  }

  sumQuantities () {
    let totals = []

    this.valueTargets.forEach((target, index) => {
      const value = Number(target.value)
      const quantity = Number(this.quantityTargets[index].value)
      const discount = Number(this.discountTargets[index].value)
      const totalItemQuantity = Number(this.totalQuantityTargets[index].value)

      totals.push((value * quantity) - (discount / totalItemQuantity))
    })

    return totals.reduce((acc, curr) => (curr + acc), 0 )
  }

  setValueToTargets(targets, value, index) {
    targets[index].innerHTML = value
    targets[index].dispatchEvent(new Event('change'))
  }

  formValid() {
    return this.hasProductChecked()
  }

  hasProductChecked() {
    return this.productCheckedTargets.find( input => input.checked ) && this.quantityTargets.find( input => input.value > 0 )
  }
}
