import dropdown from 'semantic-ui-dropdown'
import { Controller } from 'stimulus'

// Controller que busca data remota para popular um dropdown

// Data attributes
//  resource-url: Url para a requisição.
//    Ex.: '/sale_channels/:sale_channel_id/price_tables'
//  resource-name: Root do json que será retornado pela url acima.
//  Ex.:
//    JSON retornado: { sale_channels: [] }
//    resource-name: 'sale_channels'

// targets:
//   dropdown: O dropdown que será populado
//   urlParam: Um input que o valor selecionado fará parte da url para buscar os dados.
//     Esse input deverá conter um atributo "data-param-name", que é a parte da url que será substituída pelo valor do input
//
//     Ex.: <input data-target="dropdown-async-populate.urlParam" data-param-name=":sale_channel_id">


export default class extends Controller {
  static targets = ['dropdown', 'urlParam']

  connect() {
    this.resourcesUrl = this.data.get('resource-url')
    this.resourceName = this.data.get('resource-name')
  }

  async handleChange () {
    this.fetchedData = await this.fetchData()
    this.populateDropdown()
  }

  async fetchData() {
    return await fetch(this.parsedUrl())
    .then(response => response.json())
    .catch(_ => { return { [this.resourceName]: [] } })
  }

  parsedUrl() {
    if (this.urlParamTargets.length < 1) return this.resourcesUrl

    let parsedUrl = this.resourcesUrl

    this.urlParamTargets.forEach(target => {
      parsedUrl = parsedUrl.replace(target.dataset.paramName, target.value)
    })

    return parsedUrl
  }

  populateDropdown() {
    this.clearOptions()

    this.fetchedData[this.resourceName].forEach( data => {
      this.dropdownTarget.options.add(new Option(data.name, data.id))
    })
  }

  clearOptions() {
    while (this.dropdownTarget.options.length) this.dropdownTarget.remove(0)
    const UiDropdownText = this.dropdownTarget.parentElement.querySelector('.ui.dropdown > .text')

    if (UiDropdownText) UiDropdownText.innerHTML = ''
  }
}
