import numeral from 'numeral'
import 'numeral/locales'

const moneyFormat = '0,0.00'

const proratePriceAndDiscounts = (event, fieldDataTargetToChange = null, calculatedValue = null) => {
  fieldDataTargetToChange =
    fieldDataTargetToChange || `sku-price-kit-item.${event.target.getAttribute('data-target').split('.')[1]}`
  const rowParent = event.target.closest('tr')
  const tableComponent = document.querySelector(`[data-collapse-id="${rowParent.getAttribute('data-row-id')}"]`)
  const rows = $(tableComponent).find('[data-controller="sku-price-kit-item"]')
  const prorateRows = setProrateRows(rows)

  const totalQuantity = prorateRows.reduce((quantity, obj) => quantity + obj.quantity, 0)
  const skuItemValue =
    fieldDataTargetToChange === 'sku-price-kit-item.percentageDiscount'
      ? normalizeStringToFloat(event.target.value)
      : calculatedValue || event.target.value

  setProrateValueInRows(prorateRows, skuItemValue, totalQuantity, tableComponent, fieldDataTargetToChange)
}

const setProrateRows = rows => {
  const prorateRows = []
  for (let i = 0; i < rows.length; i++) {
    const quantityTextContent = rows[i].querySelector('[id="quantity"]').textContent.match(/\d+/)
    const skuRow = document.querySelector(`[data-row-id="row#${rows[i].getAttribute('data-sku-price-relation-id')}"]`)
    const quantity = quantityTextContent ? normalizeStringToFloat(quantityTextContent.input.replace('x', '')) : 1
    const discount =
      parseFloat(rows[i].querySelector('[data-target="sku-price-kit-item.percentageDiscount"]').getAttribute('data-original-value')) !== 0
        ? (parseFloat(skuRow.querySelector('[data-target="sku-price.percentageDiscount"]').getAttribute('data-original-value')) / 100.0) *
          parseFloat(rows[i].querySelector('[data-target="sku-price-kit-item.initialValue"]').getAttribute('data-original-value'))
        : rows[i].querySelector('[data-target="sku-price-kit-item.moneyDiscount"]').getAttribute('data-original-value')
    const prorateRow = {
      skuPriceRelationId: rows[i].getAttribute('data-sku-price-relation-id'),
      skuPriceId: rows[i].getAttribute('data-sku-price-id'),
      unitPrice: rows[i].querySelector('[data-target="sku-price-kit-item.initialValue"]').getAttribute('data-original-value'),
      skuPrice: normalizeStringToFloat(skuRow.querySelector('[data-target="sku-price.initialValue"]').value),
      originSkuPrice: normalizeStringToFloat(rows[i].getAttribute('origin-product-sku-price')),
      skuTotalPrice:
        normalizeStringToFloat(skuRow.querySelector('[data-target="sku-price.initialValue"]').value) * quantity,
      quantity: quantity,
      discount: discount,
    }

    prorateRows.push(prorateRow)
  }

  return prorateRows
}

const setProrateValueInRows = (prorateRows, totalValue, totalQuantity, currentTable, fieldDataTarget) => {
  const totalSum = applyProrate(prorateRows, totalValue, totalQuantity, currentTable, fieldDataTarget)

  if (totalSum !== totalValue) {
    const diff = (normalizeStringToFloat(totalSum) - normalizeStringToFloat(totalValue))

    const indexQuantityOne = prorateRows.findIndex(prorateRow => {
      return prorateRow.quantity === 1
    })

    const indexDivisibleOne = prorateRows.findIndex(prorateRow => {
      return (prorateRow.quantity % Math.abs(parseFloat(diff))).toFixed(2) === '0.00'
    })

    if (indexQuantityOne !== -1 || indexDivisibleOne !== -1) {
      let newValue = 0
      let currentRow = null
      let quantity = 0
      if (indexQuantityOne !== -1) {
        currentRow = document.querySelector(`[data-sku-price-id="${prorateRows[indexQuantityOne].skuPriceId}"]`)
        quantity = prorateRows[indexQuantityOne].quantity
        newValue =
          normalizeStringToFloat(currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).value) +
          Math.abs(parseFloat(diff))
      }
      if (indexDivisibleOne !== -1) {
        currentRow = document.querySelector(`[data-sku-price-id="${prorateRows[indexDivisibleOne].skuPriceId}"]`)
        quantity = prorateRows[indexDivisibleOne].quantity
        newValue = normalizeStringToFloat(normalizeStringToFloat(currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).value))
      }
    }
  }
}

const applyProrate = (prorateRows, totalValue, totalQuantity, currentTable, fieldDataTarget) => {
  totalValue = normalizeStringToFloat(totalValue)
  let totalSum = 0

  const indexItemWithQuantityZero = prorateRows.findIndex(prorateRow => {
    return prorateRow.originSkuPrice === 0
  })
  const sumSkuPrices = prorateRows.reduce((totalPrices, obj) => totalPrices + obj.skuTotalPrice, 0)
  const sumOriginSkusValues = prorateRows.reduce(
    (totalPrices, obj) => totalPrices + obj.originSkuPrice * obj.quantity,
    0
  )

  for (let index = 0; index < prorateRows.length; index++) {
    const currentRow = currentTable.querySelector(`[data-sku-price-id="${prorateRows[index].skuPriceId}"]`)
    const quantityDiv = currentRow.querySelector('[id=quantity]')?.textContent?.match(/\d+/)
    const quantity = quantityDiv ? normalizeStringToFloat(quantityDiv.input.replace('x', '')) : 1
    const skuPrice = prorateRows[index].skuPrice

    let unitPrice = 0.0
    if (indexItemWithQuantityZero === -1) {
      const params = {
        skuPrice,
        sumSkuPrices,
        totalValue,
        quantity,
        prorateRows,
        totalQuantity,
        field: fieldDataTarget,
        originSkuValue: prorateRows[index].originSkuPrice,
        sumOriginSkusValues: sumOriginSkusValues,
      }
      unitPrice = calculateValueByField(params)
    } else {
      const params = {
        totalQuantity,
        quantity,
        totalValue,
        prorateRows,
        field: fieldDataTarget,
      }
      unitPrice = calculateValueByField(params, true)
    }

    const targetUnitPrice = normalizeStringToFloat(
      currentRow.querySelector(`[data-target="sku-price-kit-item.initialValue"]`).getAttribute('data-original-value')
    )

    if (fieldDataTarget) {
      if (fieldDataTarget === 'sku-price-kit-item.totalValueLabel') {
        if (unitPrice !== targetUnitPrice) unitPrice = targetUnitPrice

        currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).textContent = `R$${numeral(
          unitPrice * quantity
        ).format(moneyFormat)}`
      } else if (fieldDataTarget === 'sku-price-kit-item.percentageDiscount') {
        currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).value = totalValue
        setTotalValue(currentRow, targetUnitPrice * quantity - targetUnitPrice * quantity * (totalValue / 100.0))
      } else if (fieldDataTarget === 'sku-price-kit-item.moneyDiscount') {
        currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).value = numeral(normalizeValueToInput(unitPrice)).format(moneyFormat)
        currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).setAttribute('data-original-value', unitPrice)
        currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).setAttribute('value', normalizeValueToInput(unitPrice))
        prorateRows[index].unitPrice = normalizeValueToInput(unitPrice)
        setTotalValue(currentRow, targetUnitPrice * quantity - unitPrice)
      } else {
        if (fieldDataTarget === 'sku-price-kit-item.initialValue') {

          currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).setAttribute('data-original-value', unitPrice)
        }

        currentRow.querySelector(`[data-target="${fieldDataTarget}"]`).setAttribute('value', normalizeValueToInput(unitPrice))
        prorateRows[index].unitPrice = normalizeValueToInput(unitPrice)
        setTotalValue(currentRow, unitPrice * quantity)
        totalSum = totalSum + unitPrice * quantity
      }
    } else {
      if (unitPrice !== targetUnitPrice) unitPrice = targetUnitPrice

      currentRow.querySelector('[data-target="sku-price-kit-item.totalValueLabel"]').textContent = `R$${numeral(
        normalizeStringToFloat(unitPrice * quantity)
      ).format(moneyFormat)}`

      currentRow.querySelector('[data-target="sku-price-kit-item.initialValue"]').setAttribute(
        'value',
        normalizeValueToInput(normalizeStringToFloat(unitPrice))
      )

      currentRow.querySelector('[data-target="sku-price-kit-item.percentageDiscount"]').setAttribute('value', 0)
      currentRow.querySelector('[data-target="sku-price-kit-item.moneyDiscount"]').setAttribute('value', 0)
    }
  }
  return totalSum
}

const setTotalValue = (row, value) => {
  row.querySelector('[data-target="sku-price-kit-item.totalValueLabel"]').textContent = `R$${numeral(
    normalizeStringToFloat(value)
  ).format(moneyFormat)}`
}

const valuesIsInvalid = (currentRow, quantity, fieldName, value) => {
  const unitPriceSku = currentRow.querySelector('[data-target="sku-price-kit-item.initialValue"]').getAttribute('value')
  const discountValue = currentRow.querySelector('[data-target="sku-price-kit-item.moneyDiscount"]').getAttribute('value')

  if (fieldName === 'sku-price-kit-item.moneyDiscount') {
    return value >= normalizeStringToFloat(unitPriceSku) * quantity
  }
  if (fieldName === 'sku-price-kit-item.initialValue') {
    if (normalizeStringToFloat(value) === 0) return true

    return value * quantity <= normalizeStringToFloat(discountValue)
  }

  return false
}

const calculateValueByField = (params, hasValueZero = false) => {
  const { totalValue, totalQuantity, quantity, field, originSkuValue, sumOriginSkusValues } = params

  let percentage = 0.0
  let unitPrice = 0.0

  if (field === 'sku-price-kit-item.percentageDiscount') {
    return totalValue
  }
  if (field === 'sku-price-kit-item.moneyDiscount') {
    if (totalValue === 0) return 0.0
    if (hasValueZero) {
      percentage = calculatePercentageByQuantity(totalQuantity, quantity)
      unitPrice = calculateUnitValueByQuantity(totalValue, quantity, percentage)

      return unitPrice * quantity
    }
    percentage = calculatePercentageByPrice(originSkuValue, sumOriginSkusValues)
    unitPrice = calculateUnitValueByPrice(totalValue, percentage, quantity)

    return unitPrice * quantity
  }
  if (['sku-price-kit-item.initialValue', 'sku-price-kit-item.totalValueLabel'].includes(field) || field === null) {
    if (hasValueZero) {
      percentage = calculatePercentageByQuantity(totalQuantity, quantity)
      unitPrice = calculateUnitValueByQuantity(totalValue, quantity, percentage)

      return unitPrice
    }
    percentage = calculatePercentageByPrice(originSkuValue, sumOriginSkusValues)
    unitPrice = calculateUnitValueByPrice(totalValue, percentage)

    return unitPrice
  }
}

const calculateUnitValueByQuantity = (totalValue, quantity, percentage) => {
  const unitPrice = (percentage * totalValue) / quantity

  return parseFloat(unitPrice)
}

const calculateUnitValueByPrice = (totalValue, percentage) => {
  const unitPrice = totalValue * percentage

  return parseFloat(unitPrice)
}

const calculatePercentageByQuantity = (totalQuantity, quantity) => {
  return quantity / totalQuantity
}

const calculatePercentageByPrice = (skuPrice, sumSkuPrices) => {
  return skuPrice / sumSkuPrices
}

const prorateAllFields = rowId => {
  const rowParent = document.querySelector(`[data-row-id="row#${rowId}"]`)
  const tableComponent = document.querySelector(`[data-collapse-id="row#${rowId}"]`)
  const rows = $(tableComponent).find('[data-controller="sku-price-kit-item"]')

  const prorateRows = setProrateRows(rows)

  const totalQuantity = prorateRows.reduce((quantity, obj) => quantity + obj.quantity, 0)

  setProrateAllValuesInRows(prorateRows, rowParent, totalQuantity, tableComponent)
}

const setProrateAllValuesInRows = (prorateRows, rowParent, totalQuantity, currentTable) => {
  for (let index = 0; index < prorateRows.length; index++) {
    const parentInitialValue = rowParent.querySelector('[data-target="sku-price.initialValue"]').getAttribute('data-original-value')

    applyProrate(prorateRows, parentInitialValue, totalQuantity, currentTable, null)
  }
}

const normalizeValueToInput = value => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(/[^\d,]/g, '').replace(',', '.'))
      .toString()
      .replace('.', ',')
  }

  return value
    .toString()
    .replace('.', ',')
}

const calculateDiscountToKit = (event) => {
  const parentTable = $(event.target).closest('table')
  const parentRow = parentTable.closest('tr')[0]
  const rowId = parseFloat(parentRow.getAttribute('data-collapse-id').replace('row#', ''))
  const row = document.querySelector(`[data-row-id="row#${rowId}"]`)
  const inputMoneyDiscount = row.querySelector('[data-target="sku-price.moneyDiscount"]')
  const valueInputParent = normalizeStringToFloat(row.querySelector('[data-target="sku-price.initialValue"]').value)
  const totalInputParent = normalizeStringToFloat(
    row.querySelector('[data-target="sku-price.totalValueLabel"]').textContent.replace('R$', '')
  )
  const totalChildrenInputMoneyDiscount = normalizeStringToFloat(
    parentTable.find('[data-target="sku-price-kit-item.moneyDiscount"]').toArray().reduce((acc, currentElement) => acc + parseFloat(valueByUpdatedElement(currentElement)), 0)
  )

  inputMoneyDiscount.value = totalChildrenInputMoneyDiscount
  inputMoneyDiscount.setAttribute('data-original-value', valueInputParent - totalInputParent)
  inputMoneyDiscount.setAttribute('value', normalizeValueToInput(valueInputParent - totalInputParent))
}

const valueByUpdatedElement = (element) => {
  if (normalizeStringToFloat(element.value) === normalizeStringToFloat(parseFloat(element.getAttribute('data-original-value')).toFixed(2))) {
    return normalizeStringToFloat(element.getAttribute('data-original-value'))
  } else {
    return normalizeStringToFloat(element.value)
  }
}

const sendDataToAPI = async event => {
  const targetEvent = event.target || event
  const currentRow = targetEvent.closest('.current-row-sku')
  const collapseId =
    currentRow
      ?.closest('table')
      ?.closest('tr')
      ?.getAttribute('data-collapse-id') || null
  const parentRow = collapseId === null ? currentRow : document.querySelector(`[data-row-id="${collapseId}"]`)
  const url = parentRow.getAttribute('data-sku-price-update-url')
  const params = JSON.stringify({ sku_price: getUpdateParams(parentRow, currentRow, collapseId) })
  const csrf_token = document.querySelector('[name="csrf-token"]').content

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
    body: params,
  }).catch(error => error)

  if (response.ok && (isKitItemUpdated(currentRow) || isParentKitUpdated(currentRow))) {
    return window.location.href = `${window.location.pathname}${mountParamsToRedirection(currentRow)}`
  }

  return response
}

const isKitItemUpdated = (elementRow) => {
  return $(document).find(`tr[data-row-id='row#${elementRow.getAttribute('data-sku-price-relation-id')}'`).length > 0
}

const isParentKitUpdated = (elementRow) => {
  return $(elementRow).next().find('#kit-item-price-data-table').length > 0
}

const mountParamsToRedirection = (currentRow = null) => {
  if (currentRow == null) return

  const currentSkuRow = $(currentRow).data('row-id') || $(currentRow).data('product-sku-id')
  const currentSkuRowId = currentSkuRow.toString().replace(/\D/g,'')
  let paramsToRedirect = `?updated_row=${currentSkuRowId}`

  if (currentParams().get('term')) {
    paramsToRedirect += `&term=${currentParams().get('term')}`
  }

  if (currentParams().get('page')) {
    paramsToRedirect += `&page=${currentParams().get('page')}`
  }

  return paramsToRedirect
}

const getUpdateParams = (parentRow, currentRow, collapseId) => {
  let tableKitItems = null
  const parentId = parentRow.getAttribute('data-row-id')
  if (collapseId === null) {
    const tableRow = document.querySelector(`[data-collapse-id="${parentId}"]`)
    tableKitItems = tableRow.querySelector('table')
  } else {
    tableKitItems = currentRow.closest('table')
  }
  if (tableKitItems === null) {
    return setPayload(parentRow, null, false)
  }

  const rows = $(tableKitItems.querySelector('tbody')).find('tr')

  return setPayload(parentRow, rows)
}

const setPayload = (parentRow, rows, isKit = true) => {
  const kitItems = []
  const payload = {
    price: getValue(parentRow, 'initialValue', false, true),
    discount: getValue(parentRow, 'percentageDiscount', false, true),
    discount_value: getValue(parentRow, 'moneyDiscount', false, true),
  }

  if (!rows || rows.length === 0) return payload

  for (let index = 0; index < rows.length; index++) {
    kitItems.push({
      price: getValue(rows[index], 'initialValue', true, true),
      discount: getValue(rows[index], 'percentageDiscount', true, true),
      discount_value: getValue(rows[index], 'moneyDiscount', true, true),
      product_sku_id: getValue(rows[index], 'productSkuId', true),
      sku_price_id: getValue(rows[index], 'SkuPriceId', true),
      price_table_id: getValue(rows[index], 'PriceTableId', true)
    })
  }

  if (rows.length > 0) {
    payload['kit_items'] = kitItems
  }

  return payload
}

const getValue = (row, inputDataTarget, isKit = false, useOriginalValue = false) => {
  const dataTargetInit = isKit ? 'sku-price-kit-item' : 'sku-price'
  const element = row.querySelector(`input[data-target="${dataTargetInit}.${inputDataTarget}"]`)
  const value =
    isKit && useOriginalValue && normalizeStringToFloat(element.value) === normalizeStringToFloat(parseFloat(element.getAttribute('data-original-value')).toFixed(2))
      ? element.getAttribute('data-original-value')
      : element.value

  return normalizeStringToFloat(value)
}

const normalizeStringToFloat = (value, toFix = false, decimalCharacterBreak = ',') => {
  if (!value) return 0.0
  if (typeof value === 'number') return parseFloat(value)
  if (decimalCharacterBreak === '.') {
    return parseFloat(value)
  }
  let newValue = value
  if (value.includes('.') && value.includes(',')) {
    newValue = parseFloat(value.replace('.', '').replace(',', '.'))
  } else if (value.includes(',') && !value.includes('.')) {
    newValue = parseFloat(value.replace(',', '.'))
  } else if (!value.includes(',') && value.includes('.')) {
    newValue = parseFloat(value)
  } else {
    newValue = parseFloat(value)
  }

  if (toFix) return parseFloat(newValue)

  return newValue
}

const currentParams = () => {
  const queryString = window.location.search

  return new URLSearchParams(queryString)
}

export {
  proratePriceAndDiscounts,
  prorateAllFields,
  normalizeStringToFloat,
  normalizeValueToInput,
  calculateDiscountToKit,
  sendDataToAPI,
  valuesIsInvalid,
  currentParams
}
