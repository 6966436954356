import { Controller } from 'stimulus'
import numeral from 'numeral'
import 'numeral/locales'
import {
  proratePriceAndDiscounts,
  prorateAllFields,
  normalizeStringToFloat,
  normalizeValueToInput,
  calculateDiscountToKit,
  sendDataToAPI,
  valuesIsInvalid,
} from '../utils/skuPrice'

import $ from 'jquery'

const moneyFormat = '0,0.00'

export default class extends Controller {
  static targets = [
    'initialValue',
    'percentageDiscount',
    'moneyDiscount',
    'totalValueLabel',
    'totalValueInput',
    'productId',
  ]

  initialize() {
    numeral.locale('pt-BR')
  }

  connect() {
    this.skuPriceMsgContainer = document.querySelector('[data-attr="skuPriceMsgContainer"]')
    this.skuPriceMsgText = document.querySelector('[data-attr="skuPriceMsgText"]')
  }

  closeModal(id) {
    $(`[data-attr="cancel-expansion-confirm-${id}"]`).modal('hide')
  }

  async handleChangeKitItem(event) {
    event.target.setAttribute('data-original-value', normalizeStringToFloat(event.target.value))
    const currentRow = event.target.closest('tr')

    const quantityDiv = currentRow.querySelector('[id=quantity]')?.textContent?.match(/\d+/)
    const quantity = quantityDiv ? normalizeStringToFloat(quantityDiv.input.replace('x', '')) : 1

    const isInvalid = valuesIsInvalid(
      currentRow,
      quantity,
      event.target.getAttribute('data-target'),
      event.target.value
    )

    proratePriceAndDiscounts(event)

    if (isInvalid) {
      event.target.parentElement.classList.add('error')
      const isInitialValue = event.target.getAttribute('data-target') === 'sku-price-kit-item.initialValue'

      if (isInitialValue) {
        const oldValue =
          normalizeStringToFloat(
            currentRow.querySelector('[data-target="sku-price-kit-item.totalValueLabel"]').textContent.replace('R$', '')
          ) / quantity

        currentRow.querySelector('[data-target="sku-price-kit-item.initialValue"]').setAttribute(
          'value',
          normalizeValueToInput(oldValue)
        )
      }

      const message =
        normalizeStringToFloat(event.target.value) === 0 && isInitialValue
          ? 'Valor necessariamente deve ser maior que 0.'
          : 'Valor do desconto não pode ser maior do que o valor do produto ou maior do que 100%.'
      this.displayResponseMessage(message, 'negative')
      return false
    }

    this.clearOpositeDiscount(event)
    this.calculateTotal(event)
    this.calculateValueParentField(event)
    calculateDiscountToKit(event)

    const result = await sendDataToAPI(event)
    const text = await result.text()
    const response = await JSON.parse(text)
    this.clearErrorMsgs()

    if (result.ok) {
      this.displayResponseMessage(response.success, 'positive')
    } else {
      event.target.parentElement.classList.add('error')
      this.displayResponseMessage(response.errors, 'negative')
    }
  }

  changeDisabledInputs(element) {
    const parentRow = $(element).closest('tr')
    const inputs = parentRow.find('input')

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = !inputs[i].disabled
    }
  }

  calculateValueParentField(event) {
    const parentTable = $(event.target).closest('table')
    const rowId = parentTable.closest('tr')[0].getAttribute('data-collapse-id')
    const row = document.querySelector(`[data-row-id="${rowId}"]`)

    const valuesInputs = parentTable.find('[data-target="sku-price-kit-item.initialValue"]')
    const totalLabels = parentTable.find('[data-target="sku-price-kit-item.totalValueLabel"]')
    const quantityDivs = parentTable.find('[id="quantity"]')

    const valueInputParent = row.querySelector('[data-target="sku-price.initialValue"]')
    const totalInputParent = row.querySelector('[data-target="sku-price.totalValueLabel"]')

    let totalValues = 0
    let totalSum = 0

    for (let index = 0; index < valuesInputs.length; index++) {
      const quantityDiv = quantityDivs[index].textContent?.match(/\d+/)
      const quantity = quantityDiv ? parseFloat(quantityDiv.input.replace('x', '')) : 1

      totalValues = totalValues + normalizeStringToFloat(valuesInputs[index]?.getAttribute('data-original-value')) * quantity
      totalSum = totalSum + normalizeStringToFloat(totalLabels[index].textContent.replace('R$', '').replace('.', ''))
    }

    if (event.target.getAttribute('data-target') === 'sku-price-kit-item.initialValue') {
      valueInputParent.setAttribute('value', totalValues)
    }

    totalInputParent.textContent = numeral(totalSum).format(moneyFormat)
  }

  async handleCancelExpand(event) {
    const rowId = event.target.closest('.modal').getAttribute('data-row-id')
    const rowComponent = document.querySelector(`[data-element-target-id="${rowId}"]`)
    const buttonDiv = rowComponent.closest('.actions-button-div')
    const row = document.querySelector(`[data-row-id="${rowId}"]`)
    const trigger = new CustomEvent('unexpand')
    const cancelBtn = buttonDiv.querySelector('[title="cancel-icon"]').parentElement
    const listBtn = buttonDiv.querySelector('[title="list-icon"]').parentElement

    cancelBtn.dispatchEvent(trigger)

    cancelBtn.hidden = true
    listBtn.hidden = false

    this.changeDisabledInputs(buttonDiv)
    this.clearAllErrorsMsgs(rowId, row)
    this.closeModal(rowId.replace('row#', ''))
  }

  clearRowAndDependentsData(row) {
    const rowId = Number.parseInt(row.getAttribute('data-row-id').replace('row#', ''))
    const percentageDiscountsParent = row.querySelector('[data-target="sku-price.percentageDiscount"]')
    const valueInputParent = row.querySelector('[data-target="sku-price.initialValue"]')
    const discountInputParent = row.querySelector('[data-target="sku-price.moneyDiscount"]')
    const totalInputParent = row.querySelector('[data-target="sku-price.totalValueLabel"]')

    percentageDiscountsParent.value = 0
    discountInputParent.value = 0
    totalInputParent.textContent = `R$${numeral(valueInputParent.value).format(moneyFormat)}`
    prorateAllFields(rowId)
  }

  clearOpositeDiscount(event) {
    const thisTargets = event.target.dataset.target

    if (thisTargets.includes('sku-price-kit-item.percentageDiscount')) {
      this.clearOppositeKitParentDiscount(event, 'percentageDiscount')
      this.clearOppositeKitItemsDiscount(event, 'moneyDiscount')
      this.moneyDiscountTarget.value = 0
    }
    if (thisTargets.includes('sku-price-kit-item.moneyDiscount')) {
      this.clearOppositeKitParentDiscount(event, 'percentageDiscount')
      this.clearOppositeKitItemsDiscount(event, 'percentageDiscount')
      this.percentageDiscountTarget.value = 0
    }
  }

  clearOppositeKitParentDiscount(event, fieldToClear) {
    const parentKitRowId = $(event.target).closest('tr').data('product-sku-id')
    const parentKitRow = document.querySelector(`[data-row-id="row#${parentKitRowId}"]`)
    const parentKitInputs = parentKitRow.querySelectorAll('input')

    this.clearDiscountsFor(parentKitInputs, 'sku-price', fieldToClear)
  }

  clearOppositeKitItemsDiscount(event, fieldToClear) {
    const kitItemPanel = event.target.closest('tr.oms-collapse-element.is-visible')
    const kitItemInputs = kitItemPanel.querySelectorAll('input')

    this.clearDiscountsFor(kitItemInputs, 'sku-price-kit-item', fieldToClear)
  }

  clearDiscountsFor(inputs, discountTargetType, fieldToClear) {
    for (let index = 0; index < inputs.length; index++) {
      let currentInputElement = inputs[index]

      if ([`${discountTargetType}.${fieldToClear}`].includes(currentInputElement.dataset.target)) {
        currentInputElement.value = 0
        currentInputElement.setAttribute('value', 0)
        currentInputElement.setAttribute('data-original-value', 0)
      }
    }
  }

  displayResponseMessage(text, className) {
    const styleToRemove = className === 'negative' ? 'positive' : 'negative'

    this.skuPriceMsgContainer.classList.remove(styleToRemove)
    this.skuPriceMsgContainer.classList.add(className)
    this.skuPriceMsgText.textContent = text
    this.skuPriceMsgContainer.classList.remove('hidden')
  }

  clearErrorMsgs() {
    this.moneyDiscountTarget.parentElement.classList.remove('error')
    this.initialValueTarget.parentElement.classList.remove('error')
    this.percentageDiscountTarget.parentElement.classList.remove('error')
  }

  clearAllErrorsMsgs(rowId, parentRow) {
    const tableComponent = document.querySelector(`[data-collapse-id="${rowId}"]`)
    const rows = $(tableComponent).find('[data-controller="sku-price-kit-item"]')

    parentRow.querySelector('[data-target="sku-price.percentageDiscount"]').classList.remove('error')
    parentRow.querySelector('[data-target="sku-price.initialValue"]').classList.remove('error')
    parentRow.querySelector('[data-target="sku-price.moneyDiscount"]').classList.remove('error')
    parentRow.querySelector('[data-target="sku-price.totalValueLabel"]').classList.remove('error')

    for (let index = 0; index < rows.length; index++) {
      rows[index].querySelector('[data-target="sku-price-kit-item.percentageDiscount"]').classList.remove('error')
      rows[index].querySelector('[data-target="sku-price-kit-item.initialValue"]').classList.remove('error')
      rows[index].querySelector('[data-target="sku-price-kit-item.moneyDiscount"]').classList.remove('error')
      rows[index].querySelector('[data-target="sku-price-kit-item.totalValueLabel"]').classList.remove('error')
    }
  }

  getUpdateParams() {
    const initialValue = numeral(this.initialValueTarget.value).value()
    const percentageDiscount = this.percentageDiscountTarget.value
    const discount = numeral(this.moneyDiscountTarget.value).value()

    return {
      price: initialValue,
      discount: percentageDiscount,
      discount_value: discount
    }
  }

  async updateDiscount() {
    const url = this.data.get('update-url')
    const params = JSON.stringify({ sku_price: this.getUpdateParams() })
    const csrf_token = document.querySelector('[name="csrf-token"]').content

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: params,
    }).catch(error => error)

    return response
  }

  getPercentageDiscountValue() {
    const initialValue = numeral(this.initialValueTarget.value || 0).value()
    const percentageDiscount = numeral(this.percentageDiscountTarget.value / 100.0 || 0).value()

    return initialValue * percentageDiscount
  }

  calculateTotal(event) {
    const initialValue = parseFloat(this.initialValueTarget.getAttribute('data-original-value'))
    const percentageDiscountValue = this.getPercentageDiscountValue()
    const moneyDiscount = parseFloat(this.moneyDiscountTarget.getAttribute('data-original-value'))

    const row = event.target.closest('tr')
    const quantityDiv = row.querySelector("[id='quantity']")?.textContent?.match(/\d+/)
    const quantity = quantityDiv ? parseFloat(quantityDiv.input.replace('x', '')) : 1

    let total = 0
    let discountValue = percentageDiscountValue ? percentageDiscountValue * quantity : moneyDiscount

    total = initialValue * quantity - discountValue

    const formatedTotal = numeral(total).format(moneyFormat)
    const totalFixed = total

    this.totalValueLabelTarget.textContent = `R$${numeral(formatedTotal).format(moneyFormat)}`
    this.totalValueInputTarget.value = totalFixed

    return totalFixed
  }
}
