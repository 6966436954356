import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['text', 'container', 'input', 'hiddenInput', 'list', 'listItem', 'blankslate', 'actions']

  open() {
    this.blankslateTarget.hidden = this.listItemTargets.length !== 0
    this.listTarget.hidden = false

    setTimeout(() => {
      this.inputTarget.focus()
      this.preselect()
    }, 350) // Needs to wait container transition end to focus on input
  }

  search({ target }) {
    const { value } = target
    const results = this.filterResults(value)

    this.actionsTarget.hidden = true
    this.updateOptionsList(results)
    this.preselect()
  }

  updateOptionsList(results) {
    this.blankslateTarget.hidden = results.length !== 0

    this.listItemTargets.forEach(item => {
      item.hidden = !results.includes(item.textContent)
    })
  }

  preselect() {
    const firstValidOption = this.listItemTargets.find(item => item.hidden === false)

    this.removePreselection()

    if (!firstValidOption) return

    firstValidOption.style.backgroundColor = 'var(--ch-color-neutral-2)'
  }

  removePreselection() {
    this.listItemTargets.forEach(item => item.style.backgroundColor = '')
  }

  getOptionsList() {
    return this.listItemTargets.map(item => ({
      label: item.textContent,
      value: item.value,
    }))
  }

  filterResults(value) {
    const param = value.toLowerCase().replace(/\\/g, "\\\\")
    const options = this.getOptionsList().map(option => option.label)

    return options.filter(option => option.toLowerCase().search(param) !== -1)
  }

  select({ target }) {
    const { value, textContent } = target

    this.handleSelect(value, textContent)
  }

  handleSelect(value, text) {
    this.inputTarget.value = text.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim()
    this.hiddenInputTarget.value = value
    this.actionsTarget.hidden = false
    this.listTarget.hidden = true
  }

  clear() {
    setTimeout(() => {
      this.inputTarget.value = ''
      this.hiddenInputTarget.value = ''
      this.actionsTarget.hidden = true
      this.listTarget.hidden = true

      this.listItemTargets.forEach(item => item.hidden = false)
    }, 350)
  }


  handleKeydown(event) {
    const { key } = event

    if (!this.containerTarget.classList.contains('is-visible')) return

    switch (key) {
      case 'ArrowDown':
        this.removePreselection()
        this.onMoveDown()
        break
      case 'ArrowUp':
        this.removePreselection()
        this.onMoveUp()
        break
      case 'Enter':
        this.onEnter(event)
      default:
        // Do nothing
        break
    }
  }

  onMoveDown() {
    const firstChild = this.listItemTargets[0]

    let nextSibling = document.activeElement.nextElementSibling

    if (!this.listItemTargets.includes(document.activeElement) || nextSibling === null) {
      nextSibling = firstChild
    }

    while (nextSibling.hidden) {
      nextSibling = nextSibling.nextElementSibling || firstChild
    }

    nextSibling.focus()
  }

  onMoveUp() {
    const firstChild = this.listItemTargets[0]
    const lastChild = this.listItemTargets[this.listItemTargets.length - 1]

    let previousSibling = document.activeElement.previousElementSibling

    if (document.activeElement === firstChild) {
      previousSibling = lastChild
    }

    while (previousSibling.hidden) {
      previousSibling = previousSibling.previousElementSibling || lastChild
    }

    previousSibling.focus()
  }

  onEnter(event) {
    event.preventDefault()

    const firstValidOption = this.getFirstValidOption()

    if (!firstValidOption) return

    const { value, textContent } = firstValidOption

    this.handleSelect(value, textContent)
  }

  getFirstValidOption() {
    const activeElement = this.listItemTargets.find(item => item === document.activeElement)
    const visibleOption = this.listItemTargets.find(item => item.hidden === false)

    return activeElement || visibleOption
  }
}
