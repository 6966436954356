import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['template', 'nestedFields', 'container']

  connect() {
    this.addAssociation()
  }

  addAssociation() {
    const newTemplateInputParsed = this.newTemplate()
    const [elem] = newTemplateInputParsed.children

    this.containerTarget.appendChild(newTemplateInputParsed)
    this.callAfterInsertCallback(elem)
  }

  callAfterInsertCallback(elem) {
    // it expects that the function on callafterinsert attr has an 'elem' argument
    const funcString = this.data.get('callafterinsert')
    eval(funcString)
  }

  removeAssociation(event) {
    event
      .currentTarget
      .closest('[data-target="nested-attributes.nestedFields"]')
      .remove()
  }

  newTemplate() {
    const template = this.templateTarget.content.cloneNode(true)
    return this.parseTemplateInputs(template)
  }

  parseTemplateInputs(template) {
    const nestedIndex = new Date().getTime()
    const templateInputs =  template.querySelectorAll('input,select[name]')

    templateInputs.forEach( input => {
      // DO NOT CHANGE FORMAT ID <> NAME POSITIONS
      this.formatIdAttrBasedOnName(input, nestedIndex)
      this.formatNameAttr(input, nestedIndex)
    })

    return template
  }

  formatNameAttr(input, nestedIndex) {
    const name = input.getAttribute('name')
    const newName = name.replace('][', `][${nestedIndex}][`)

    input.setAttribute('name', newName)
  }

  formatIdAttrBasedOnName(input, nestedIndex) {
    const name = input.getAttribute('name')
    const newId = name.replace('][', `_${nestedIndex}_` ).replace('[', '_').replace(']', '')

    input.setAttribute('id', newId)
  }
}
