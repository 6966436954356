import { Controller } from "stimulus"
import $ from 'jquery'

import modal from 'semantic-ui-modal'

$.fn.modal = modal

export default class extends Controller {
  static targets = ['input', 'dropArea', 'title', 'description', 'icon', 'message']

  connect() {
    this.options = JSON.parse(this.data.get('options'))
  }

  allowDrop(event) {
    event.preventDefault()
  }

  handleDragEnter(event) {
    this.toggle('dragover', event)
  }

  handleDragLeave(event) {
    this.toggle('default', event)
  }

  toggle(state, event) {
    event.preventDefault()

    this.updateState(state)
  }

  updateState(state) {
    const action = state !== 'default' ? 'add' : 'remove'
    const { messages, icons } = this.options

    this.dropAreaTarget.classList[action]('is-active')
    if (this.hasIconTarget) this.iconTarget.setAttribute('src', icons[state])
    this.titleTarget.textContent = messages[state].title
    this.descriptionTarget.textContent = messages[state].description || ''
  }

  handleDrop(event) {
    event.preventDefault()

    const { files } = event.dataTransfer

    this.inputTarget.files = files
    this.handleChange()
  }

  clearInput() {
    this.inputTarget.value = null
  }

  handleChange() {
    if (this.inputTarget.files.length === 0) return

    this.upload()

    return !this.isValid() && this.hasMessageTarget
      ? this.addErrorMessage()
      : this.finish()
  }

  upload() {
    const loader = this.dropAreaTarget.querySelector('.loader')
    const { messages } = this.options

    this.dropAreaTarget.classList.add('is-active')
    if (this.hasIconTarget) this.iconTarget.setAttribute('hidden', 'hidden')
    this.titleTarget.textContent = messages.loader
    this.descriptionTarget.textContent = this.inputTarget.files[0].name

    loader.removeAttribute('hidden')
  }

  isValid() {
    const fileType = this.inputTarget.files[0].type
    const acceptedTypes = this.inputTarget
      .getAttribute('accept')
      .split(',')
      .map(type => type.trim())

    return acceptedTypes.includes(fileType)
  }

  addErrorMessage() {
    this.messageTarget.hidden = false
    this.updateState('default')
  }

  finish() {
    this.options && this.options.preventSubmit
      ? this.dispatchCallback(this.options)
      : this.handleModalOnSubmit()
  }

  handleModalOnSubmit() {
    if(this.options && this.options.closeModalOnSubmit)
      $('.modal.import-adjust').modal('hide')
    this.inputTarget.form.submit()
  }

  dispatchCallback(options) {
    const customEvent = new CustomEvent(options.customEvent, {
      detail: {
        file: this.inputTarget.files[0],
        input: this.inputTarget,
      },
    })

    window.dispatchEvent(customEvent)
    this.updateState('default')
  }
}
