import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  toggle ({ currentTarget: tab }) {
    const { id } = tab
    const activeTabPanel = this.getPanel(id)

    this.clear()

    tab.classList.add('is-active')
    tab.setAttribute('aria-selected', true)
    activeTabPanel.hidden = false
  }

  clear () {
    this.tabTargets.forEach(tab => {
      tab.classList.remove('is-active')
      tab.setAttribute('aria-selected', false)
    })

    this.panelTargets.forEach(panel => panel.hidden = true)
  }

  getPanel (id) {
    return this.panelTargets.find(panel => (
      panel.dataset.tabsPanel === id
    ))
  }
}
