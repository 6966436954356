import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.url = this.data.get('url')
    this.pollingInterval = Number(this.data.get('interval')) || 5000
    this.startPolling()
  }

  async startPolling() {
    if (this.data.get('activated') !== 'true') return

    const response = await fetch(this.url + '.json')

    const body = await response.json()

    if (!body.still_processing) return this.reloadPage()

    await this.wait()

    this.startPolling()
  }

  reloadPage() {
    window.location.replace(window.location.href + '?redirect_from_polling=true')
  }

  wait() {
    return new Promise(resolve => setTimeout(resolve, this.pollingInterval))
  }
}
