import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ["freeInput", "discountFormInput", "discountValueInput"]

  connect() {
    this.handleChange()
  }

  handleChange() {
    const discountFormParent = this.discountFormInputTarget.closest('.field.select')

    if (this.freeInputTarget.value == 'true') {
      $("[data-value='percentual']", discountFormParent).trigger('click')

      this.discountFormInputTarget.dispatchEvent(new Event('change'))
      discountFormParent.classList.add('is-disabled')

      this.discountValueInputTarget.value = '10000'
      this.discountValueInputTarget.dispatchEvent(new Event('input'))
      this.discountValueInputTarget.classList.add('is-disabled')
    } else {
      discountFormParent.classList.remove('is-disabled')
      this.discountValueInputTarget.classList.remove('is-disabled')
    }
  }
}
