import { Controller } from 'stimulus'
import $ from 'jquery'
import { parseEanFile } from '../utils'

import modal from 'semantic-ui-modal'

$.fn.modal = modal

export default class extends Controller {
  static targets = ['notFoundMsgContainer', 'notFoundMsg', 'importButton', 'stockSelectInput', 'sizeNotAllowed']

  connect() {
    this.notFoundMsg = this.data.get('not-found-msg')
    this.fileInput = null
    this.enableImportFileButton()
  }

  enableImportFileButton() {
    const originStockSelected = this.getOriginStockId()

    if (originStockSelected) {
      this.importButtonTarget.classList.remove('is-disabled')
    }
  }

  async parseEANs(event) {
    const { file, input } = event.detail
    const eanFile = await parseEanFile(file)
    const stockOriginID = this.getOriginStockId()
    const products = await this.getproducts(eanFile, stockOriginID)

    this.fileInput = input
    this.closeModal()
    this.resetSkusImport()
    this.handleSizeNotAllowed(products)
    this.handleProductsFound(products.product_skus)
    this.handleProductsNotFound(products.eans_not_found)
  }

  async getproducts(eanList, stockOriginID) {
    const url = '/product_skus/search_by_eans'
    const csrfToken = $('meta[name="csrf-token"]').attr('content')

    return $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken)
      },
      data: {
        origin_stock_id: stockOriginID,
        eans: eanList
      },
    }).done(function(data) {
      return data
    })
  }

  resetSkusImport() {
    this.clearNotFoundMsg()
    this.clearOldSkus()
  }

  resetImportInputs() {
    this.resetSkusImport()
    this.clearFileInput()
    this.enableImportFileButton()
    this.newSku()
  }

  clearNotFoundMsg() {
    this.notFoundMsgContainerTarget.classList.add('hidden')
  }

  clearFileInput() {
    if (this.fileInput?.value) {
      this.fileInput.value = null
    }
  }

  getOriginStockId() {
    const originSelect = this.stockSelectInputTargets.find( stockInput => stockInput.dataset.stockSelectType == 'origin_stock')
    const stockOriginInput = originSelect.querySelector('input:checked')

    if (stockOriginInput) {
      return stockOriginInput.value
    }
  }

  closeModal() {
    $('[data-modal="import_ean_txt"]').modal('hide')
  }

  clearOldSkus() {
    const movementItems = document.querySelectorAll('[data-attr="movement_items"] .nested-fields')

    movementItems.forEach(item => this.removeSkuItem(item))
  }

  removeSkuItem(item) {
    item.hidden = true
    item.querySelector('input[name*=_destroy]').value = true
  }

  handleProductsFound(products) {
    products.forEach(product => this.populateProduct(product))
  }

  handleProductsNotFound(eans) {
    if (eans.length <= 0) return

    this.notFoundMsgContainerTarget.classList.toggle('hidden', false)
    this.notFoundMsgTarget.innerHTML = `${this.notFoundMsg} ${eans}`
  }

  handleSizeNotAllowed(products) {
    if (!products.error) return

    this.sizeNotAllowedTarget.classList.toggle('hidden', false)
    this.sizeNotAllowedTarget.innerHTML = `${products.error}`
  }

  newSku() {
    $('[data-associations="movement_items"]')[0].click()
  }

  populateProduct(product) {
    this.newSku()

    const productName = `${product.code} - ${product.description}`
    const newOption = new Option(productName, product.id, true, true)
    const $newProductElement = $('[data-attr="movement_items"]').find('.nested-fields:last-child')
    const $selectProductSkuInput = $newProductElement.find('[data-transfer-items="true"]')
    const $productQuantityInput = $newProductElement.find('[data-attr="sku-quantity"]')
    const $productSerialNumber = $newProductElement.find('[data-attr="serial-number-label"]')

    $selectProductSkuInput.append(newOption)
    // TODO: find a way to trigger change event and remove this method call
    window.loadSkuBatches($selectProductSkuInput)
    // $selectProductSkuInput.trigger('change')
    // TODO2: Get batches from /search_by_eans to avoid N+1 requests
    $productQuantityInput.val(product.quantity)
    $productSerialNumber.text(Array.isArray(product.serial_number) ? product.serial_number.join(',') : product.serial_number)
    $productSerialNumber.parent().removeClass('element-hidden')
  }
}
