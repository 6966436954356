import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'input',
    'gridDefinitionBtn',
    'generateBtn',
    'skuGrid',
    'skuGridTable',
    'skuGridBtn',
    'removeSkuBtn',
    'configKitBtn',
  ]

  connect() {
    const { observer, config } = this.createSkuGridObserver()
    const pageAction = document.body.dataset.currentAction
    const form = document.getElementById('new-product-form')
    this.toggleHideNcmAndOrigin()

    if (form) {
      form.addEventListener('keypress', function(e) {
        if (e.key == 'Enter' && !/textarea/i.test((e.target || e.srcElement).tagName)) {
          e.preventDefault()
        }
      })
    }

    if (['edit', 'update'].includes(pageAction) && !this.inputTarget.checked) {
      this.inputTarget.disabled = true
    }

    observer.observe(this.skuGridTarget, config)
    this.handleCustomVariations(this.inputTarget.checked)
    this.toggleAdditionalVariation(this.inputTarget.checked)
    this.toggleDefaultButtons(this.inputTarget.checked)
    this.toggleAddSkuButton(this.skuGridTarget)
  }

  handleChange({ target }) {
    const { checked } = target

    this.toggleHideNcmAndOrigin()
    this.toggleSkuGrid(checked)
    this.toggleAdditionalVariation(checked)
    this.toggleKitSkus(checked)
    this.toggleFractionable(checked)
    this.toggleDefaultButtons(checked)
    this.toggleAddSkuButton()
  }

  toggleHideNcmAndOrigin() {
    const kit_checked = document.querySelector('[data-target="product-kind.input"]').checked
    let ncm_field = document.getElementById('ncm-input-div')
    let origin_field = document.getElementById('origin-input-div')

    ncm_field.hidden = kit_checked
    origin_field.hidden = kit_checked

    if (kit_checked) {
      ncm_field.children[0].classList.remove('required')
      origin_field.children[0].classList.remove('required')
    } else {
      ncm_field.children[0].classList.add('required')
      origin_field.children[0].classList.add('required')
    }
  }

  toggleDefaultButtons(visiblity) {
    this.configKitBtnTarget.hidden = !visiblity
    this.gridDefinitionBtnTarget.hidden = visiblity
    this.gridDefinitionBtnTarget.style.display = visiblity ? 'none' : ''
    this.skuGridBtnTargets.forEach(button => (button.hidden = visiblity))
  }

  createSkuGridObserver() {
    return {
      observer: new MutationObserver(this.handleSkuGridMutation),
      config: { attributes: true },
    }
  }

  handleSkuGridMutation = mutations => {
    mutations.forEach(mutation => {
      const { target: skuGrid } = mutation

      this.toggleAddSkuButton(skuGrid)
    })
  }

  toggleAddSkuButton() {
    const { checked } = this.inputTarget
    const tableRows = this.skuGridTableTarget.querySelectorAll('tbody > tr')

    if (!checked && tableRows.length > 0) return

    this.generateBtnTarget.style.display = ''
    this.generateBtnTarget.hidden = false
  }

  toggleAdditionalVariation(checked) {
    const fieldset = document.querySelector('[data-attr="additional_sku_variants_fieldset"]')

    if (!fieldset) return

    fieldset.disabled = checked
    fieldset.hidden = checked
  }

  toggleSkuGrid(checked) {
    this.handleCustomVariations(checked)
    this.handleSkuGridTableRows()
  }

  toggleKitSkus(checked) {
    const destroySkuInputQuery = '[data-attr="kit-item-config-modal"] input[name*=_destroy]'
    const removeSkuInputs = document.querySelectorAll(destroySkuInputQuery)

    removeSkuInputs.forEach(input => {
      input.value = !checked
    })
  }

  toggleFractionable(isKit) {
    const field = document.getElementById('product_fractionable')

    field.disabled = isKit
    field.classList.toggle('disabled', isKit)
    if (isKit) field.checked = false
  }

  handleCustomVariations(checked) {
    const customVariations = this.skuGridTarget.querySelectorAll('[data-attr="variation-custom"]')

    if (customVariations) {
      customVariations.forEach(variation => {
        const inputs = variation.querySelectorAll('select, input')

        variation.hidden = checked

        if (inputs.length > 0) inputs.forEach(input => (input.disabled = checked))
      })
    }
  }

  handleSkuGridTableRows() {
    const removeButtons = this.removeSkuBtnTargets
    const pageAction = document.body.dataset.currentAction

    removeButtons.forEach((button, index) => {
      if (['edit', 'update', 'create'].includes(pageAction) && index === 0) return

      button.click()
    })
  }

  handleSkuCreation() {
    this.toggleAdditionalVariation(this.inputTarget.checked)
  }
}
