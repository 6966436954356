import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['quantity', 'checkedAmount', 'motiveIcon', 'motiveSelect']

  checkAmount() {
    const quantity = Number(this.quantityTarget.textContent)
    const checkedAmount = Number(this.checkedAmountTarget.value)

    this.toggleIconVisibility(quantity, checkedAmount)
    this.toggleSelectVisibility(quantity, checkedAmount)
    this.toggleSelectState(quantity, checkedAmount)
  }

  toggleIconVisibility(quantity, checkedAmount) {
    const isSameAmount = quantity === checkedAmount

    this.motiveIconTarget.hidden = !isSameAmount
  }

  toggleSelectVisibility(quantity, checkedAmount) {
    const isSameAmount = quantity === checkedAmount

    this.motiveSelectTarget.disabled = isSameAmount
    this.motiveSelectTarget.hidden = isSameAmount
    this.motiveSelectTarget.parentElement.hidden = isSameAmount
  }

  toggleSelectState(quantity, checkedAmount) {
    const isValidAmount = quantity >= checkedAmount
    const isSameAmount = quantity === checkedAmount

    this.checkedAmountTarget.parentElement.classList.toggle('error', !isValidAmount)
    this.motiveSelectTarget.disabled = !isValidAmount || isSameAmount
    this.motiveSelectTarget.parentElement.classList.toggle('disabled', !isValidAmount)
  }
}
