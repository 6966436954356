import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addressSelect", "input"]

  connect() {
    this.dropdownSelect = null
    this.customerAddresses = null

    this.populate()
  }

  async populate() {
    if (this.hasAddressSelectTarget) {
      const customer = this.addressSelectTarget.dataset.customer
      if (customer) {
        this.customerAddresses = await this.getAddresses(customer)
        this.buildAddressOptions(this.customerAddresses)
        this.setAddressSelected()
      }
    }
  }

  async handleCustomerChange(event) {
    this.customerAddresses = event.target.value ? await this.getAddresses(event.target.value) : []

    this.fillAddress()

    this.buildAddressOptions(this.customerAddresses)
  }

  setAddressSelected() {
    if (this.addressSelectTarget.dataset.selected) {
      this.addressSelectTarget.value = this.addressSelectTarget.dataset.selected
    }
  }

  async getAddresses(customer_id) {
    const {customer_addresses} =
      await fetch(this.customerAddressUrl(customer_id), { mode: 'no-cors'})
      .then(response => response.json())
      .catch(_ => { return {customer_addresses: []} })

    return customer_addresses
  }

  customerAddressUrl(customer_id = 'null') {
    return `/customers/${customer_id}/addresses`
  }

  buildAddressOptions(addresses) {
    this.clearAddressSelect()

    Array.prototype.map.call(addresses, e => {
      this.addressSelectTarget.options.add(new Option(e.kind, e.id))
    })
  }

  clearAddressSelect() {
    while (this.addressSelectTarget.options.length) this.addressSelectTarget.remove(0)

    this.addressSelectTarget.options.add(new Option('Novo Endereço', " ", true, true))
  }

  fillAddress() {
    const address = this.getSelectedAddress()

    this.inputTargets.forEach(input => {
      let hasDefaultValue = address && address[input.dataset.provider]

      if (input.dataset.provider == 'complement') {
        hasDefaultValue = address && address['detail']
      }

      if (hasDefaultValue) {
        if (input.dataset.provider == 'complement') {
          input.value = address['detail']
        } else {
          input.value = address[input.dataset.providerValue] || address[input.dataset.provider]
        }
        
        this.setSelectValueToDropdownText(input, address[input.dataset.provider])
        this.setDisableToInputOrSelect(input, true)
      } else {
        input.value = ''
        this.setSelectValueToDropdownText(input, '')
        this.setDisableToInputOrSelect(input, false)
      }
    })
  }

  setSelectValueToDropdownText(select, value) {
    const hasText = select.parentElement.querySelector('.ui.dropdown > .text')

    if (hasText) hasText.innerHTML = value
  }

  setDisableToInputOrSelect(input, value) {
    const target = input.tagName == 'SELECT' ? input.parentElement : input

    target.classList.toggle('is-disabled', value)
  }

  getSelectedAddress() {
    return this.customerAddresses.find(e => e.id == this.addressSelectTarget.value)
  }
}
