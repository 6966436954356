import { Controller } from "stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["container", "menu", "toggleNavButton", "oldMenu", "newMenu", "buttonToggleMenu", "headerLogo", "headerBrand", "newBrandLogo", "labelFixedMenu"]

  connect () {
    document.body.addEventListener('mouseover', this.hover)
  }

  hover = event => {
    const container = this.containerTarget
    const toggleNavButton = this.toggleNavButtonTarget

    if (!toggleNavButton.checked) return

    const eventType = container.contains(event.target) ? 'mouseenter' : 'mouseout'

    this.toggleHoverClass(eventType)
  }

  toggleHoverClass = (event) => {
    
    const toggleNavButton = this.toggleNavButtonTarget
    const type = typeof event === 'string' ? event : event.type
    const action = type === 'mouseenter' ? 'add' : 'remove'
    
    this.newBrandLogoTarget.hidden = !((type === 'mouseenter' || !toggleNavButton.checked) && this.oldMenuTarget.hidden)
    this.containerTarget.classList[action]('is-hovered')
  }

  open = () => {
    
    this.containerTarget.classList.add('is-open')
  }

  close = () => {
    this.containerTarget.classList.remove('is-open')
  }

  expand = () => {
    this.newBrandLogoTarget.hidden = 'false'
    this.containerTarget.classList.add('is-expanded')
    this.containerTarget.classList.remove('is-hovered')
    this.labelFixedMenuTarget.innerHTML = 'Recolher menu'
  }

  minimize = () => {
    this.newBrandLogoTarget.hidden = 'true'
    this.containerTarget.classList.remove('is-expanded')
    this.labelFixedMenuTarget.innerHTML = 'Fixar menu aberto'
  }

  toggleMenu = event => {
    const isChecked = event.target.checked

    isChecked ? this.open() : this.close()
  }

  toggleNav = event => {
    const isChecked = event.target.checked

    isChecked ? this.minimize() : this.expand()
    Cookies.set('menu_collapsed', isChecked)
  }

  toggleNewMenu = () => {
    const isNewMenuStyle = Cookies.get('old_menu')

    isNewMenuStyle == "true" ? this.showNewMenu() : this.showOldMenu()
  }

  showNewMenu = () => {
    Cookies.set('old_menu', false)
    
    this.headerLogoTarget.src = '/omni_logo.svg'
    this.containerTarget.classList.add('navbar_new')
    this.oldMenuTarget.hidden = true
    this.newBrandLogoTarget.hidden = false
    this.headerBrandTarget.hidden = true
    this.newMenuTarget.hidden = false
    this.buttonToggleMenuTarget.innerHTML = `
    <i class="ch-icon uil uil-history"></i>
    <label>Mudar para o menu antigo</label>
    `
  }

  showOldMenu = () => {
    Cookies.set('old_menu', true)
    this.containerTarget.classList.remove('navbar_new')
    this.headerLogoTarget.src = '/icon_oms_white.svg'
    this.newMenuTarget.hidden = true
    this.newBrandLogoTarget.hidden = true
    this.headerBrandTarget.hidden = false
    this.oldMenuTarget.hidden = false
    this.buttonToggleMenuTarget.innerHTML = `
    <i class="ch-icon uil uil-history"></i>
    <label>Mudar para o menu novo</label>
    `
  }

  toggleMenuItem = event => {
    const parent = event.currentTarget.parentElement

    parent.classList.toggle('is-open')
  }
}
