import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['addCocoonBtn', 'ean']

  connect() {
    const lastEanValue = this.getLastEanValue()

    let lastEan = (lastEanValue && lastEanValue.match(/\d+$/)) ? lastEanValue.match(/\d+$/) : lastEanValue

    lastEan = Array.isArray(lastEan) ? Number(lastEan[0]) : lastEan

    this.eanPrefix = lastEanValue
    this.eanStartGenerator = lastEan || this.data.get('initialValue')
    this.lastEanGenerated = null
  }

  newEan() {
    let emptyField = this.getLastEmptyEanField()

    if (!emptyField) this.addCocoonBtnTarget.click()

    emptyField = this.getLastEmptyEanField()

    emptyField.value = this.getNewEanValue()
  }

  getNewEanValue() {
    return this.getNewValueFromNumber() || this.getNewValueFromString()
  }

  getNewValueFromNumber() {
    if (!Number.isInteger(this.eanStartGenerator)) return null

    const fullEan = this.eanPrefix
    const startNumber = this.lastEanGenerated ? this.lastEanGenerated + 1 : this.eanStartGenerator + 1

    this.lastEanGenerated = startNumber

    const padNumb = String(startNumber).padStart(4, '0')

    return fullEan.replace(/\d+$/, padNumb)
  }

  getNewValueFromString() {
    const padValue = String(this.eanTargets.length).padStart(4, '0')

    return `${this.eanStartGenerator}${padValue}`
  }

  getLastEanValue() {
    const values = this.eanTargets.map(e => e.value ).filter(e => !!e)

    return values[values.length - 1]
  }

  getLastEmptyEanField() {
    return this.eanTargets.find(e => !e.value )
  }
}
