import { Controller } from 'stimulus'
import numeral from 'numeral'
import 'numeral/locales'
import { proratePriceAndDiscounts, calculateDiscountToKit, sendDataToAPI, currentParams, normalizeStringToFloat } from '../utils/skuPrice'

const moneyFormat = '0,0.00'

const openUpdatedRowPriceTable = () => {
  const updatedPriceId = currentParams().get('updated_row')

  if (updatedPriceId) {
    if (!$(`[data-collapse-id="row#${updatedPriceId}"]`).hasClass('is-visible')) {
      return $(`[data-element-target-id="row#${updatedPriceId}"][data-action="click->collapse#toggle"]`).click()
    }
  }
}

function forceDisableOnInitialize(updatedPriceId) {
  const inputToDisable = document.querySelector(`[data-sku-price-id="${updatedPriceId}"]`).querySelectorAll('input')

  inputToDisable.forEach(input => {
    input.disabled = true
  })
}

const verifyKitIconState = () => {
  if (!currentParams().get('updated_row')) return

  const rowId = currentParams().get('updated_row')
  const parentKitRow = document.querySelector(`[data-row-id="row#${rowId}"]`)
  const collapseElement = document.querySelector(`[data-collapse-id="row#${rowId}"]`)
  const inputs = parentKitRow.querySelectorAll('input')
  const cancelBtn = document.querySelector(`tr[data-row-id='row#${rowId}`).querySelector('[title="cancel-icon"]').parentElement
  const listBtn = document.querySelector(`tr[data-row-id='row#${rowId}`).querySelector('[title="list-icon"]').parentElement

  if (collapseElement.classList.contains('is-visible')) {
    for (let index = 0; index < inputs.length; index++) {
      inputs[index].disabled = !inputs[index].disabled
    }

    cancelBtn.hidden = false
    listBtn.hidden = true
  }
}

export default class extends Controller {
  static targets = ['initialValue', 'percentageDiscount', 'moneyDiscount', 'totalValueLabel', 'totalValueInput']

  initialize() {
    numeral.locale('pt-BR')

    $(document).on('turbolinks:load', function() {
      openUpdatedRowPriceTable()
      verifyKitIconState()
      forceDisableOnInitialize(currentParams().get('updated_row'))
    })
  }

  connect() {
    this.skuPriceMsgContainer = document.querySelector('[data-attr="skuPriceMsgContainer"]')
    this.skuPriceMsgText = document.querySelector('[data-attr="skuPriceMsgText"]')
    this.displaySuccessMessageOnUpdatedPrice()
  }

  async handleChange(event) {
    event.target.setAttribute('data-original-value', normalizeStringToFloat(event.target.value))
    this.clearOpositeDiscount(event)

    proratePriceAndDiscounts(event)

    if (event.target.getAttribute('data-target').split('.')[0] === 'sku-price') this.calculateTotal(event)

    const result = await sendDataToAPI(event)
    const text = await result.text()
    const response = await JSON.parse(text)

    this.clearErrorMsgs()

    if (result.ok) {
      this.displayResponseMessage(response.success, 'positive')
    } else {
      event.target.parentElement.classList.add('error')
      this.displayResponseMessage(response.errors, 'negative')
    }
  }

  clearOpositeDiscount(event) {
    const thisTargets = event.target.dataset.target

    if (thisTargets.includes('sku-price.percentageDiscount')) {
      this.clearOppositeDiscountChildren(event, 'moneyDiscount')
      this.moneyDiscountTarget.value = 0
    }
    if (thisTargets.includes('sku-price.moneyDiscount')) {
      this.clearOppositeDiscountChildren(event, 'percentageDiscount')
      this.percentageDiscountTarget.value = 0
    }
  }

  clearOppositeDiscountChildren(event, fieldToClear) {
    const parentRow = event.target.closest('tr')
    const rowId = parentRow.querySelector('input#sku_prices_product_sku_id').getAttribute('value')
    const tableComponent = document.querySelector(`[data-collapse-id="row#${rowId}"]`)
    const rows = $(tableComponent).find('[data-controller="sku-price-kit-item"]')

    for (let index = 0; index < rows.length; index++) {
      let currentElement = rows[index].querySelector(`[data-target="sku-price-kit-item.${fieldToClear}"]`)
      currentElement.value = 0
      currentElement.setAttribute('value', 0)
      currentElement.setAttribute('data-original-value', 0)
    }
  }

  displayResponseMessage(text, className) {
    const styleToRemove = className === 'negative' ? 'positive' : 'negative'

    this.skuPriceMsgContainer.classList.remove(styleToRemove)
    this.skuPriceMsgContainer.classList.add(className)
    this.skuPriceMsgText.textContent = text
    this.skuPriceMsgContainer.classList.remove('hidden')
  }

  displaySuccessMessageOnUpdatedPrice() {
    if (currentParams().get('updated_row')) {
      this.displayResponseMessage('Preço atualizado com sucesso.', 'positive')
    }
  }

  clearErrorMsgs() {
    this.moneyDiscountTarget.parentElement.classList.remove('error')
    this.initialValueTarget.parentElement.classList.remove('error')
    this.percentageDiscountTarget.parentElement.classList.remove('error')
  }

  getUpdateParams() {
    const initialValue = numeral(this.initialValueTarget.value).value()
    const percentageDiscount = this.percentageDiscountTarget.value
    const discount = numeral(this.moneyDiscountTarget.value).value()

    return {
      price: initialValue?.toFixed(2),
      discount: percentageDiscount,
      discount_value: discount?.toFixed(2),
    }
  }

  async updateDiscount() {
    const url = this.data.get('update-url')
    const params = JSON.stringify({ sku_price: this.getUpdateParams() })
    const csrf_token = document.querySelector('[name="csrf-token"]').content

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: params,
    }).catch(error => error)

    return response
  }

  getPercentageDiscountValue() {
    const initialValue = numeral(this.initialValueTarget.value || 0).value()
    const percentageDiscount = numeral(this.percentageDiscountTarget.value / 100.0 || 0).value()

    return initialValue * percentageDiscount
  }

  calculateTotal(event) {
    const initialValue = parseFloat(this.initialValueTarget.getAttribute('data-original-value'))
    const percentageDiscountValue = this.getPercentageDiscountValue()
    const moneyDiscount = parseFloat(this.moneyDiscountTarget.getAttribute('data-original-value'))
    let total = 0
    let discountValue = percentageDiscountValue ? percentageDiscountValue : moneyDiscount

    total = initialValue - discountValue

    const formatedTotal = numeral(total).format(moneyFormat)
    const totalFixed = Number(total).toFixed(2)

    this.totalValueLabelTarget.textContent = `R$${numeral(formatedTotal).format(moneyFormat)}`
    this.totalValueInputTarget.setAttribute('data-original-value', totalFixed)
    this.totalValueInputTarget.setAttribute('value', totalFixed)

    return totalFixed
  }

  async handleExpand(event) {
    const buttonDiv = event.currentTarget.parentElement
    const elementId = buttonDiv.getAttribute('data-element-target-id').split('#')[1]
    const collapseElement = document.querySelector(`[data-collapse-id="row#${elementId}"]`)

    const cancelBtn = buttonDiv.parentElement.querySelector('[title="cancel-icon"]').parentElement
    const listBtn = buttonDiv.parentElement.querySelector('[title="list-icon"]').parentElement

    if (collapseElement.classList.contains('is-visible')) {
      const rowId = event.target.closest('[data-element-target-id]').getAttribute('data-element-target-id')
      const modalComponent = $.find(`[data-attr="cancel-expansion-confirm-${rowId.replace('row#', '')}"]`)[0]
      modalComponent.setAttribute('data-row-id', rowId)
      this.showModal(rowId.replace('row#', ''))
    } else {
      this.openExpandedSessionActions(event.target)
      cancelBtn.hidden = false
      listBtn.hidden = true
    }
  }

  showModal(id) {
    $(`.modal[data-attr="cancel-expansion-confirm-${id}"]`).modal('show')
  }

  openExpandedSessionActions(element) {
    this.changeDisabledInputs(element)
  }

  changeDisabledInputs(element) {
    const parentRow = $(element).closest('tr')
    const inputs = parentRow.find('input')

    for (let index = 0; index < inputs.length; index++) {
      inputs[index].disabled = !inputs[index].disabled
    }
  }
}
