import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["container"]

  close () {
    this.startRemoveTransition()
    setTimeout(this.remove.bind(this), 300)
  }

  startRemoveTransition() {
    this.containerTarget.classList.add('animation-fadeOut')
  }

  remove() {
    this.containerTarget.classList.add('hidden')
    this.containerTarget.classList.remove('animation-fadeOut')
  }
}
