import numeral from 'numeral'
import 'numeral/locales'

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["day", "month", "year", "total", "unmaskedTotal"]

  initialize () {
    numeral.locale('pt-BR')
  }

  connect() {
    this.toggleMonthDays()
  }

  calculateMonthGoal() {
    const totalGoals = this.sumTotalGoals()

    this.totalTarget.value = this.formatValue(totalGoals)
    this.unmaskedTotalTarget.value = totalGoals
  }

  handleDateChange() {
    this.toggleMonthDays()
    this.calculateDailyGoals()
  }

  sumTotalGoals() {
    let dailyGoals = []

    this.activeDays()
      .forEach(e => {
        let dayValue = e.querySelector('[data-attr="day-value"]').value
        dailyGoals.push(Number(dayValue)) 
      })

    return dailyGoals.reduce((acc, curr) => ( acc + curr), 0).toFixed(2)
  }

  calculateDailyGoals() {
    let dailyGoal = numeral(this.totalTarget.value).value()/this.activeDays().length
    dailyGoal = dailyGoal.toFixed(2)

    this.dayTargets.forEach(e => {
      let dayValueDisplayInput = e.querySelector('[data-attr="day-value-display"]')
      let dayValueInput = e.querySelector('[data-attr="day-value"]')

      dayValueDisplayInput.value = this.formatValue(dailyGoal)
      dayValueInput.value = dailyGoal
    })
  }

  toggleMonthDays() {
    let totalDays = new Date(this.yearTarget.value, this.monthTarget.value, 0).getDate()

    this.dayTargets.forEach((dayContainer) => {
      let day = dayContainer.querySelector('[data-attr="day"]').value
      let disabledDay = day > totalDays
      let dayContainerInputs = dayContainer.querySelectorAll('input')

      dayContainer.hidden = disabledDay
      dayContainerInputs.forEach(input => input.disabled = disabledDay)
    })
  }

  activeDays() {
    return this.dayTargets
          .filter(e => 
            getComputedStyle(e).visibility == 'visible' && 
            getComputedStyle(e).display != 'none')
  }

  formatValue(value) {
    value = value.replace('.', ',')
    return numeral(value).format('0,0.00')
  }
}
