import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect () {
    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const container = this.containerTarget
    const containerPageYOffset = container.scrollTop + container.offsetHeight

    container.classList.toggle('is-sticky', window.pageYOffset >= containerPageYOffset)
  }
}
