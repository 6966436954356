import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "overlay"]

  connect () {
    document.body.addEventListener('click', this.closeOnOutsideClick)
  }

  toggle = () => {
    this.overlayTarget.classList.toggle('is-visible')
  }

  close = () => {
    this.overlayTarget.classList.remove('is-visible')
  }

  closeOnOutsideClick = event => {
    const button = this.buttonTarget
    const overlay = this.overlayTarget

    if ([button, overlay].includes(event.target) || button.contains(event.target) || overlay.contains(event.target)) return

    this.close()
  }
}
