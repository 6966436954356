import { Controller } from 'stimulus'
import $ from 'jquery'
import dropdown from 'semantic-ui-dropdown'
import transition from 'semantic-ui-transition'

$.fn.dropdown = dropdown
$.fn.transition = transition

export default class extends Controller {
  static targets = ['select']

  initialize() {
    $(this.selectTarget).dropdown()
    this.createIcon()
  }

  createIcon() {
    const icon = document.createElement('i')
    const iconName = this.selectTarget.dataset.dropdownIcon

    if (!iconName) return

    icon.classList.add('ch-icon', 'fal', iconName)

    const dropdownContainer = this.selectTarget.parentElement
    const dropdownFirstChild = dropdownContainer.firstChild

    if(dropdownFirstChild.isEqualNode(icon)) return

    dropdownContainer.insertBefore(icon, dropdownFirstChild)
  }
}
