import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['kind', 'destinationStock', 'destinationOrg']

  connect() {
    const { value } = this.kindTarget

    this.toggleVisibility(value)
  }

  handleChange({ target }) {
    const { value } = target

    this.resetDropdownText()
    this.toggleVisibility(value)
  }

  toggleVisibility(value) {
    this.handleDestination(this.destinationStockTarget, value, 'between_deposits')
    this.handleDestination(this.destinationOrgTarget, value, 'between_organizations')
  }

  handleDestination(destinationField, kindValue, kindType) {
    destinationField.hidden = kindValue !== kindType

    destinationField.querySelectorAll('input').forEach(input => {
      if (kindValue !== kindType) input.checked = false
      input.disabled = kindValue !== kindType
    })
  }

  resetDropdownText() {
    this.destinationStockTarget.querySelector('.ui.dropdown > .text').innerHTML = ''
    this.destinationOrgTarget.querySelector('.ui.dropdown > .text').innerHTML = ''
  }
}
