import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["profileTypeInput", "inputsWrapper"]

  connect() {
    this.handleChange()
  }

  handleChange() {
    const disableInputs = this.profileTypeInputTarget.value !== 'seller'

    this.inputsWrapperTarget.classList.toggle('is-disabled', disableInputs)

    if (disableInputs) {
      const inputs = this.inputsWrapperTarget.querySelectorAll('input')
      inputs.forEach( input => input.checked = false )
    }
  }
}
