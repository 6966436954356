import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "additionalSkuVariantRow", "providerSkuRow", "additionalEansTable"]

  static errorMessages = {
    taken: 'já está adicionado',
    blank: 'não pode ficar em branco',
  }

  confirmChanges() {
    if (this.validateConfig()) {
      return $(this.element).modal('hide')
    }

    this.addInvalidError()
  }

  validateConfig() {
    this.resetErrorMessages('[data-attr="additional-sku-row"]', '[data-attr="provider-sku-row"]')

    const skuVariantsAreValid = this.validateTargets('additionalSkuVariantRowTargets')
    const isUnique = this.validateUniqueness()

    return skuVariantsAreValid && !isUnique
  }

  validateUniqueness() {
    const allVariationsValues = this.getAllVariationsValues()
    const uniqueValues = []
    let isUnique = false

    allVariationsValues.forEach((values) => {
      const valueAsString = JSON.stringify(values)

      isUnique = !uniqueValues.includes(valueAsString)

      uniqueValues.push(valueAsString)
    })

    this.addVariationUniquenessError(isUnique)

    return !isUnique
  }

  addVariationUniquenessError(isUnique) {
    if (isUnique) return

    const skuVariantRows = this.modalTarget.querySelectorAll(`[data-attr="additional-sku-row"]:not([hidden])`)

    skuVariantRows.forEach(skuVariantRow => {
      this.addError($(skuVariantRow), '[data-config-sku="value"]', 'taken')
    })

  }


  getAllVariationsValues() {
    const productSkuRows = document.querySelectorAll('[data-attr="product_skus"] > tr')

    return Array.prototype.map.call(productSkuRows, row => {
      const customVariations = this.getAllCustomVariations(row)
      const additionalSkuVariations = this.getAllAdditionalSkuVariations(row)
      const combinedVariations = [...customVariations, ...additionalSkuVariations]

      let values = []

      combinedVariations.forEach(variation => values.push(variation.value))

      return values.sort((value1, value2) => value1 - value2)
    })
  }

  getAllCustomVariations(row) {
    return row.querySelectorAll('[data-attr="variation-custom"] [data-attr="value"]')
  }

  getAllAdditionalSkuVariations(row) {
    const modalName = row.querySelector('[data-modal-name]').dataset.modalName

    return document.querySelectorAll(`[data-modal="${modalName}"] [data-attr="additional-sku-row"]:not([hidden]) [data-config-sku="value"] select`)
  }

  validateTargets(targetName) {
    const ids = []
    const $targets = $(this[targetName]).filter(':visible')

    const validations = $targets.map((_index, $target) => {
      return this.validateTarget(ids, $target)
    })

    return validations.toArray().every((isValid) => isValid)
  }

  validateTarget(ids, row) {
    const $row = $(row)
    const id = $row.find('select').val();
    const value = $row.find('input:last').val();

    if (!id) {
      this.addBlankKeyError($row)
      return false
    }

    if (ids.indexOf(id) !== -1) {
      this.addFeatureExistsError($row);
      return false
    }

    ids.push(id);

    if (!value) {
      this.addBlankValueError($row);
      return false
    }

    return true;
  }

  addFeatureExistsError($row) {
    this.addError($row, '[data-config-sku="key"]', 'taken')
  }

  addBlankValueError($row) {
    this.addError($row, '[data-config-sku="value"]', 'blank')
  }

  addBlankKeyError($row) {
    this.addError($row, '[data-config-sku="key"]', 'blank')
  }

  addError($row, selector, errorKey) {
    const $element = $row.find(selector)

    $element.addClass('error')

    var isAdded = $element.find(`[data-error='${errorKey}']`).length > 0
    if (isAdded) {
      return
    }

    $element.append(`<span data-error="${errorKey}" class="error_message">
                    ${this.constructor.errorMessages[errorKey]}
                    </span>`)
  }

  resetErrorMessages(selector) {
    $(selector).find('.error').map((i, field) => {
      const $field = $(field)
      $field.find('[data-error]').remove()
      $field.removeClass('error')
    })

    const modalName = this.element.dataset.modal
    const $link = $(`[data-modal-name=${modalName}]`)
    const $td = $link.parent()

    $td.removeClass('error')
    $td.find('[data-error]').addClass('element-hidden')
    $link.removeClass('ch-button--negative').addClass('ch-button--primary')
  }

  addInvalidError() {
    const modalName = this.element.dataset.modal
    const $link = $(`[data-modal-name=${modalName}]`)
    const $td = $link.parent()

    $td.addClass('error')
    $td.find('[data-error]').removeClass('element-hidden')
    $link.addClass('ch-button--negative').removeClass('ch-button--primary')
  }
}
