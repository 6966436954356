import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["row", "selectVariant"]

  fetchVariants = event => {
    const $selectVariant = $(this.selectVariantTarget)
    const productFeatureID = $(event.target).val()
    const url = '/product_features/' + productFeatureID + '/feature_variants'

    $.getJSON(url).done(function(data) {
      var options = []

      data.feature_variants.forEach(function(variant) {
        options.push(
          '<option value=\'' + variant.id + '\'>' + variant.name + '</option>'
        )
      });

      $selectVariant.empty();
      $selectVariant.append(options.join(''));
    })
  }

  hide() {
    this.rowTarget.hidden = true
  }
}
