import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["relativeDegree", "breed", "petType", "gender", "identificationNumber", "weight", "pelageType", "petSection"]

  connect() {
    this.handleChange()
  }

  handleChange() {
    this.toggleInputsFor(this.relativeDegreeTarget.value)
  }

  toggleInputsFor(type) {
    if (type == 'pet') {
      this.setInputsForPet()
    } else {
      this.setInputsForHuman()
    }
  }

  setInputsForPet() {
    this.genderTarget.querySelector('label').textContent = 'Sexo'

    this.enableInput(this.petSectionTarget)
    this.enableInput(this.breedTarget)
    this.enableInput(this.petTypeTarget)
    this.enableInput(this.identificationNumberTarget)
    this.enableInput(this.weightTarget)
    this.enableInput(this.pelageTypeTarget)
  }

  setInputsForHuman() {
    this.genderTarget.querySelector('label').textContent = 'Gênero'

    this.disableInput(this.petSectionTarget)
    this.disableInput(this.breedTarget)
    this.disableInput(this.petTypeTarget)
    this.disableInput(this.identificationNumberTarget)
    this.disableInput(this.weightTarget)
    this.disableInput(this.pelageTypeTarget)
  }

  enableInput(container) {
    container.classList.remove('element-hidden')
    const elem = container.querySelector('input')
    if(elem) elem.disabled = false
  }

  disableInput(container) {
    container.classList.add('element-hidden')
    const elem = container.querySelector('input')
    if(elem) elem.disabled = true 
  }
}
