export default function(file) {
  const reader = new FileReader()

  return new Promise((resolve, _reject) => {
    reader.onload = function(event) {
      const contents = event.target.result
      const lines = contents
        .replace(/ +/g, '')
        .replace(/\r\n?\n/g, '\n')
        .split('\n')
      const uniqueEAN = Array.from(new Set(lines)).filter(item => item) //Remove duplicated and empty strings
      const eanCount = []

      uniqueEAN.map(ean => eanCount.push({ ean: ean.trim(), qtd: lines.filter(element => element == ean).length }))

      resolve(eanCount)
    }

    reader.readAsText(file)
  })
}
