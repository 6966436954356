import { Controller } from 'stimulus'
import $ from 'jquery'
import dropdown from 'semantic-ui-dropdown'
import transition from 'semantic-ui-transition'

$.fn.dropdown = dropdown
$.fn.transition = transition

export default class extends Controller {
  static targets = ['select']

  connect() {
    $(this.selectTarget).dropdown({
      onAdd: this.onAdd,
    })
    this.createNestedOptions()
    this.onAdd()
  }

  createNestedOptions() {
    const $options = $(this.selectTarget).siblings('.menu').find('.item')

    $options.each(this.formatOptions)
  }

  formatOptions(_, option) {
    const label = option.textContent.replace(/-(?!([A-Za-z])\w+)/g, `${String.fromCharCode(160)}${String.fromCharCode(160)}`)

    option.textContent = label
  }

  onAdd = () => {
    const $labels = $(this.selectTarget).siblings('.ui.label')

    $labels.each(this.formatLabel)
  }

  formatLabel(_, label) {
    const endOfLabelPos = label.innerHTML.search('<') // Position where the text of label ends
    const wordToReplace = label.innerHTML.substr(0, endOfLabelPos) // Label text without icon
    const formattedLabel = wordToReplace.replace(/(&nbsp;)|(^-*)/g, '', '').trim()
    const htmlToAppend = label.innerHTML.replace(wordToReplace, formattedLabel)

    label.innerHTML = htmlToAppend
  }
}
