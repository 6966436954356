import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["element"]

  toggle (event) {
    const elementId = event.currentTarget.dataset.elementTargetId || 
                      event.currentTarget.parentElement.dataset.elementTargetId
    const collapsibleElement = this.findElement(elementId)
    const elementIcon = this.findIcon(event)

    event.currentTarget.parentElement.classList.toggle('is-active')

    if (collapsibleElement) {
      collapsibleElement.classList.toggle('is-visible')
    }

    this.toggleIcon(elementIcon)
  }

  toggleIcon(icon) {
    if (icon) {
      const iconClasses = icon.classList

      iconClasses.toggle('fa-angle-down')
      iconClasses.toggle('fa-angle-up')
    }
  }

  findIcon(event) {
    return event.currentTarget.parentElement.querySelectorAll("i.ch-icon.fa-angle-up, i.ch-icon.fa-angle-down")[0]
  }

  findElement(id) {
    return this.elementTargets.find( element => element.dataset.collapseId == id )
  }
}
