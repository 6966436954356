import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['row', 'quantity', 'verifiedQuantity']

  showModalChoice() {
    $('.modal[data-attr="choice-modal-step-two"]').modal('show')
  }

  async disableAllButtons() {
    document.getElementById('closeButton').classList.add('is-disabled')
    document.getElementById('backButton').classList.add('is-disabled')
    document.getElementById('manualCheck').classList.add('is-disabled')
    document.getElementById('autoCheck').classList.add('is-disabled')
  }

  async fillVerifiedQuantities() {
    const customEvent = new Event('autoCheckCallback')
    var items = document.getElementsByClassName('ch-table')[0].getElementsByClassName('collapse-link')

    for (var index = 0; index < items.length; index++) {
      var quantityItem = items[index].getElementsByTagName('td')[6].getElementsByTagName('span')[0].innerText
      const quantityVerifiedElement = items[index]
        .getElementsByTagName('td')[7]
        .getElementsByTagName('div')[0]
        .getElementsByTagName('input')[0]

      quantityVerifiedElement.value = quantityItem
      const currentVerifiedTarget = this.verifiedQuantityTargets[index]
      currentVerifiedTarget.dispatchEvent(customEvent)
    }

    items[0]
      .getElementsByTagName('td')[7]
      .getElementsByTagName('div')[0]
      .getElementsByTagName('input')[0]
      .dispatchEvent(new Event('change'))
  }
}
