import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["element", "listeningChangeEvent"]

  connect() {
    this.attr_map = JSON.parse(this.data.get('map-values'))
    this.attr = this.data.get('attr-name')
  }

  handleChange(event) {
    this.elementTarget.setAttribute(this.attr, this.attr_map[event.target.value])
    this.notifyChange()
  }

  notifyChange() {
    this.listeningChangeEventTargets.forEach(e => {
      const eventType = e.dataset['attrChangerEventType']
      if (eventType) e.dispatchEvent(new Event(eventType))
    })
  }
}
