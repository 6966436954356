import { Controller } from 'stimulus'
import $ from 'jquery'
import { parseEanFile } from '../utils'
import { hasInvalidItemQtd, hasInvalidItemBatch } from '../utils/validateXMLItem'

import modal from 'semantic-ui-modal'
import transition from 'semantic-ui-transition'
import dimmer from 'semantic-ui-dimmer'

$.fn.modal = modal
$.fn.transition = transition
$.fn.dimmer = dimmer

export default class extends Controller {
  static targets = [
    'row',
    'ean',
    'quantity',
    'verifiedQuantity',
    'divergenceDetails',
    'message',
    'errorDescription',
    'batch',
    'manufacturingDate',
    'expirationDate',
    'batchControl',
  ]

  connect() {
    if (!this.hasSkuTargets()) return

    this.messages = JSON.parse(this.data.get('messages'))
    this.items = this.getItems()
    this.errorMessages = new Set()
    this.shouldDisableSubmitButton()
  }

  hasSkuTargets() {
    return this.hasVerifiedQuantityTarget && this.hasDivergenceDetailsTarget
  }

  isItemExpirationDateInvalid(item) {
    const { expirationDate, ean } = item

    const today = this.getCurrentDate()
    const expirationDateTime = Date.parse(this.convertStringToDate(expirationDate.value))

    if (today > expirationDateTime) {
      let errorMsg = this.messages.errors.batch_expiration_smaller_than_current_day

      return this.addError(errorMsg, ean)
    }
    return false
  }

  isItemManufacturingDateInvalid(item) {
    const { expirationDate, manufacturingDate, ean, batch, batchControl } = item
    const manufacturingDateTime = Date.parse(this.convertStringToDate(manufacturingDate.value))
    const expirationDateTime = Date.parse(this.convertStringToDate(expirationDate.value))

    if (isNaN(manufacturingDateTime) || !manufacturingDateTime) {
      if (batchControl.value === 'false') return false

      let errorMsg = this.messages.errors.manufacturing_is_required

      return this.addError(errorMsg, ean)
    }

    const today = this.getCurrentDate()

    if (today < manufacturingDateTime) {
      let errorMsg = this.messages.errors.manufacturing_date_bigger_than_current_day

      return this.addError(errorMsg, ean)
    }

    if (isNaN(expirationDateTime) || !expirationDateTime) {
      if (batchControl.value === 'false') return false

      let errorMsg = this.messages.errors.expiration_is_required
      return this.addError(errorMsg, ean)
    }

    if (manufacturingDateTime > expirationDateTime) {
      let errorMsg = this.messages.errors.manufacturing_date_bigger_than_batch_date
      return this.addError(errorMsg, ean)
    }

    return false
  }

  addError(errorMsg, ean) {
    this.errorMessages.add(errorMsg)
    this.toggleBatchError(ean.textContent, true)
    return true
  }

  getCurrentDate() {
    const currentDate = new Date()
    const currentMonth =
      currentDate.getUTCMonth() + 1 < 10 ? `0${currentDate.getUTCMonth() + 1}` : currentDate.getUTCMonth() + 1
    return Date.parse(`${currentDate.getUTCFullYear()}-${currentMonth}-${currentDate.getUTCDate()}`)
  }

  convertStringToDate(date) {
    const [day, month, year] = date.split('/')

    return `${year}-${month}-${day}`
  }

  shouldDisableSubmitButton() {
    let hasQttInvalid = false
    let hasBatchInvalid = false
    let hasManufacturingDateInvalid = false
    let hasExpirationDateInvalid = false

    this.handleErrors('clear')
    this.errorMessages = new Set()

    this.items.forEach(item => {
      if (this.isItemBatchInvalid(item)) {
        hasBatchInvalid = true
      }
      if (this.isItemQttInvalid(item)) {
        hasQttInvalid = true
      }
      if (this.isItemManufacturingDateInvalid(item)) {
        hasManufacturingDateInvalid = true
      }
      if (this.isItemExpirationDateInvalid(item)) {
        hasExpirationDateInvalid = true
      }
    })

    if (hasBatchInvalid || hasManufacturingDateInvalid || hasExpirationDateInvalid) this.handleErrors('show')
    this.toggleSubmitButton(hasQttInvalid || hasBatchInvalid || hasManufacturingDateInvalid || hasExpirationDateInvalid)
  }

  getItems() {
    if (this.items !== undefined) return this.items

    const items = []

    for (let row of this.rowTargets) {
      items.push({
        ean: $(row).find(this.eanTargets)[0],
        verifiedQtd: $(row).find(this.verifiedQuantityTargets)[0],
        qtd: $(row).find(this.quantityTargets)[0],
        batch: $(row).find(this.batchTargets)[0],
        manufacturingDate: $(row).find(this.manufacturingDateTargets)[0],
        expirationDate: $(row).find(this.expirationDateTargets)[0],
        divergence: $(row).find(this.divergenceDetailsTargets)[0],
        batchControl: $(row).find(this.batchControlTargets)[0],
      })
    }

    this.items = items

    return items
  }

  isItemQttInvalid(item) {
    const { qtd, verifiedQtd, divergence } = item

    return hasInvalidItemQtd(Number(verifiedQtd.value), Number(qtd.textContent), divergence.value)
  }

  isItemBatchInvalid(item) {
    const defaultValue = { value: '' }
    const {
      ean = defaultValue,
      batch = defaultValue,
      manufacturingDate = defaultValue,
      expirationDate = defaultValue,
      batchControl = defaultValue,
    } = item

    let hasBatchError = hasInvalidItemBatch(
      batch.value,
      manufacturingDate.value,
      expirationDate.value,
      batchControl.value
    )
    let errorMsg = this.messages.errors.batch_control[batchControl.value]

    if (batchControl.value && errorMsg && hasBatchError) this.errorMessages.add(errorMsg)

    this.toggleBatchError(ean.textContent, hasBatchError)

    return hasBatchError
  }

  toggleBatchError(ean, hasBatchError) {
    let $rowContext = $(`div[data-target="sku-entry.ean"]:contains("${ean}")`).closest('tr')

    $rowContext.toggleClass('ch-table-row--negative', hasBatchError)
  }

  toggleSubmitButton(shouldDisableSubmitButton) {
    const submitButton = document.querySelector('[data-attr="submit_xml_import"]')
    const buttonTooltip = submitButton.parentElement

    submitButton.classList.toggle('is-disabled', shouldDisableSubmitButton)
    buttonTooltip.classList.toggle('hidden-tooltip', !shouldDisableSubmitButton)
  }

  async validatesEANList(event) {
    const { file, input } = event.detail
    const conferenceFile = await parseEanFile(file)
    const products = this.rowTargets

    this.handleErrors('clear')
    this.chooseEanBasedOn(conferenceFile)
    this.checkForDivergences(conferenceFile)

    const isValid = this.isDocumentValid(products, conferenceFile) && this.isProductValid(products, conferenceFile)

    if (!isValid) {
      this.handleErrors('show')
      input.value = ''
    }

    this.toggleToReadOnly(isValid)
    this.navigateToProductTab(isValid)
  }

  chooseEanBasedOn(conferenceFile) {
    const conferenceFileEanItems = conferenceFile.map(item => item.ean)

    this.eanTargets.forEach(eanTarget => {
      if (eanTarget.textContent.includes('-')) {
        eanTarget.textContent = this.getEanFromList(eanTarget.textContent, conferenceFileEanItems)
      }
    })
  }

  getEanFromList(eanOptions, conferenceFileEanItems) {
    const eansList = eanOptions
      .replace(/ +/g, '') //remove empty spaces
      .split('-')

    return eansList.find(ean => conferenceFileEanItems.includes(ean)) || 'Ean not found'
  }

  checkForDivergences(conferenceFile) {
    const items = this.getItems().map(item => ({
      ean: item.ean.textContent,
      qtd: Number(item.qtd.textContent),
    }))

    const customEvent = new CustomEvent('checkForDivergences', {
      detail: { conferenceFile, items },
    })

    window.dispatchEvent(customEvent)
  }

  isDocumentValid(products, conferenceFile) {
    const isValid = products.length >= conferenceFile.length

    if (!isValid) this.errorMessages.add(this.messages.errors.invalid_document)

    return isValid
  }

  isProductValid(products, conferenceFile) {
    let isValid = true

    products.forEach(product => {
      const $product = $(product)
      const ean = $product.find(this.eanTargets)[0].textContent
      const foundEAN = conferenceFile.find(item => item.ean === ean)

      this.setEANQuantity($product, foundEAN)

      isValid = this.validatesProductPresence($product, foundEAN) && this.validatesProductQuantity($product, foundEAN)
    })

    return isValid
  }

  validatesProductPresence($product, foundEAN) {
    const isValid = !!foundEAN
    const $ean = $product.find(this.eanTargets)

    $ean.toggleClass('ch-text--negative', !isValid)

    if (!isValid) this.errorMessages.add(this.messages.errors.ean_not_found)

    return isValid
  }

  validatesProductQuantity($product, foundEAN) {
    const $qtd = $product.find(this.quantityTargets)
    const isValid = foundEAN && foundEAN.qtd <= Number($qtd.text())

    $qtd.toggleClass('ch-text--negative', !isValid)

    if (!isValid) this.errorMessages.add(this.messages.errors.wrong_ean_quantity)

    return isValid
  }

  setEANQuantity($product, foundEAN) {
    const verifiedTarget = $product.find(this.verifiedQuantityTargets)[0]
    const customEvent = new Event('input')
    const value = !!foundEAN ? foundEAN.qtd : ''

    verifiedTarget.value = value
    verifiedTarget.dispatchEvent(customEvent)
  }

  handleErrors(event) {
    const message = this.messageTarget
    const hide = event !== 'show'

    message.classList.toggle('hidden', hide)
    message.hidden = hide

    hide ? this.clearErrors() : this.displayErrors()
  }

  displayErrors() {
    for (let message of this.errorMessages) {
      const li = document.createElement('li')

      this.errorDescriptionTarget.append(message, li)
    }
  }

  clearErrors() {
    this.errorMessages.clear()
    this.errorDescriptionTarget.innerHTML = ''
    this.clearBackendErrors()
  }

  clearBackendErrors() {
    const backendErrorMessages = document.querySelector('#import-update-errors')

    backendErrorMessages.classList.add('hidden')
    backendErrorMessages.hidden = true
  }

  toggleToReadOnly(isValid) {
    this.verifiedQuantityTargets.forEach(input => (input.readOnly = isValid))
  }

  navigateToProductTab(isValid) {
    const productTabControl = document.querySelector('#product_data')
    const clickEvent = new Event('click')
    const importListButton = document.querySelector('[data-attr="ean_list_import"]')

    $('[data-modal="import_ean_txt"]').modal('hide')
    productTabControl.dispatchEvent(clickEvent)

    importListButton.classList.toggle('is-disabled', isValid)
  }
}
