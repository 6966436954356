import { Controller } from 'stimulus'
import numeral from 'numeral'
import 'numeral/locales'

const moneyFormat = '0,0.00'

export default class extends Controller {
  static targets = ['value', 'submit', 'displayTotal', 'totalValueExpected']

  initialize () {
    numeral.locale('pt-BR')
  }

  connect() {
    this.handleChange()
  }

  handleChange() {
    const value = this.sumPayments()
    const currentValueFormated = numeral(value).format(moneyFormat)
    const totalValueFormated = numeral(this.totalValue()).format(moneyFormat)

    if (this.hasDisplayTotalTarget) this.displayTotalTarget.innerHTML = currentValueFormated

    let isValid = currentValueFormated === totalValueFormated && this.allValuesFilled()

    this.toggleSubmit(isValid)
  }

  allValuesFilled() {
    if (this.acceptZeroTotal()) return true

    return !this.valueTargets.find( e => Number(e.dataset.boxInstallmentCalcTotalValue) === 0)
  }

  sumPayments() {
    let paymentsValues = []

    // Only count visible elements
    this.valueTargets
    .filter(e => getComputedStyle(e).visibility == 'visible' && getComputedStyle(e).display != 'none')
    .forEach(e => { paymentsValues.push(Number(e.dataset.boxInstallmentCalcTotalValue)) })

    return paymentsValues.reduce((acc, curr) => ( acc + curr), 0)
  }

  totalValue() {
    return this.data.get('totalValue') || this.totalValueExpectedTarget.value
  }

  acceptZeroTotal() {
    return this.data.get('acceptZeroTotal') == 'true'
  }

  toggleSubmit(isValid) {
    this.hasSubmitTarget && this.submitTarget.classList.toggle('is-disabled', !isValid)
  }
}
